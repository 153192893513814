import { alpha } from '@mui/material';

export const BLACK = '#000000';

const appColors = {
  CANDY_PINK: '#E57373',
  ALTO: '#e0e0e0', // MUI default btn
  ARGENT: '#bfbfbf',
  ATHENS_GRAY: '#edf0f4',
  CYAN_BLUE: '#99ABBE',
  BLUE_LOBSTER: '#0056AC',
  NEVADA: '#67757c',
  PRIMARY: {
    50: '#8098af',
    100: '#66839E',
    200: '#4D6E8E',
    300: '#33597E',
    400: '#1A456E',
    500: '#00305E',
    600: '#00264B',
    700: '#001D38',
    800: '#001326',
    900: '#000A13',
    MAIN: '#002f5d',
  },
  SECONDARY: {
    50: '#ebf2f8',
    100: '#c2d7e9',
    200: '#99bddb',
    300: '#85afd4',
    400: '#70a2cd',
    500: '#5c95c5',
    600: '#4787be',
    700: '#337ab7',
    800: '#2e6ea5',
    900: '#296292',
  },
  TEXT: {
    PRIMARY: alpha(BLACK, 0.7),
    SECONDARY_DARK: alpha(BLACK, 0.54),
    SECONDARY_LIGHT: alpha(BLACK, 0.5), // old secondary
    DISABLED: alpha(BLACK, 0.35),
  },
  ERROR: {
    LIGHT: '#EF5350',
    MAIN: '#D8524E',
  },
  WARNING: {
    LIGHT: '#FF9800',
    MAIN: '#ED6C02',
  },
  INFO: {
    LIGHT: '#03A9F4',
  },
  SUCCESS: {
    MAIN: '#4CAF50',
  },
  MOSTLY_WHITE_GRAY: '#f5f5f5',
  TOWER_GRAY: '#a6b7bf',
  SPANISH_GRAY: '#9a9a9a',
  TUNDORA: '#464646',
  WHITE: '#ffffff',
  LIGHT_GRAY: '#d5d5d5',
  LIGHT_GRAY_1: '#e9e9e9',
  COBALT_BLUE: '#0056AC',
  CRYSTAL_BELL: '#EFEFEF',
};

export default appColors;

export const {
  SUCCESS,
  CANDY_PINK,
  ALTO,
  ARGENT,
  ATHENS_GRAY,
  CYAN_BLUE,
  BLUE_LOBSTER,
  NEVADA,
  PRIMARY,
  SECONDARY,
  TOWER_GRAY,
  TUNDORA,
  MOSTLY_WHITE_GRAY,
  WHITE,
  SPANISH_GRAY,
  LIGHT_GRAY,
  LIGHT_GRAY_1,
  TEXT,
  ERROR,
  WARNING,
  INFO,
  COBALT_BLUE,
  CRYSTAL_BELL,
} = appColors;
