import { styled } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';

import { usePagination, useSorting } from 'components/organisms/Table';

import { useGetAuditRisksQuery } from '../../../state/riskMitigationPlanTab/api';
import { AuditRiskMitigationPlanTab } from '../../../types';
import { TabSectionHeading } from '../../atoms/TabSectionHeading';
import { SearchFilter } from '../../molecules/SearchFilter';
import { TemplateBasedTable } from '../TemplateBasedTable';

const StyledContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  h3 {
    margin-bottom: 0;
  }
`;

type RiskMitigationPlanTabProps = AuditRiskMitigationPlanTab['props'] & {
  auditUuid: string;
};

export const RiskMitigationPlanTab: FC<RiskMitigationPlanTabProps> = ({
  auditUuid,
  headerText,
  tableDefinition,
}) => {
  const [generalSearchString, setGeneralSearchString] = useState<string>();

  const sorting = useSorting();
  const { sortString, resetSortState } = sorting;
  const { limit, offset, pagination, setTotalRowsAmount } = usePagination();
  const { total, risks, status } = useGetAuditRisksQuery({
    auditUuid,
    limit,
    offset,
    ...(sortString && {
      sort: sortString,
    }),
    ...(generalSearchString && {
      filter: {
        generalSearchString,
      },
    }),
  });

  useEffect(() => {
    setTotalRowsAmount(total);
  }, [setTotalRowsAmount, total]);

  return (
    <>
      <StyledContainer>
        <TabSectionHeading text={headerText} />
        <SearchFilter
          onSearchClick={setGeneralSearchString}
          onResetClick={() => {
            setGeneralSearchString(undefined);
            resetSortState();
          }}
          enableReset={!!sortString}
        />
      </StyledContainer>
      <TemplateBasedTable
        columns={tableDefinition.columns}
        data={risks}
        status={status}
        pagination={pagination}
        sorting={sorting}
      />
    </>
  );
};
