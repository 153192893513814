import { alpha, styled } from '@mui/material';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

import { BLACK, TEXT } from 'config/appColors';

const StyledTreeItem = styled(TreeItem)`
  .Mui-focused {
    background-color: ${alpha(BLACK, 0.04)};
  }

  .MuiTreeItem-content {
    gap: 10px;
    padding: 6px 10px;

    .MuiTreeItem-iconContainer {
      &,
      svg {
        height: 14px;
        width: 14px;
      }
    }

    .MuiTreeItem-label {
      color: ${TEXT.SECONDARY_LIGHT};
      font-size: 1.4rem;
      line-height: 1.4rem;
      letter-spacing: 0.01rem;
    }
  }
`;

StyledTreeItem.displayName = 'StyledTreeItem';

export { StyledTreeItem };
