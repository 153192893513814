import { styled, TableCell, TableHead, TableRow } from '@mui/material';
import React, { FC } from 'react';

import { UseSorting } from 'components/organisms/Table/useSorting';
import { getTranslatedValue } from 'utils/getTranslatedValue';

import { SortOptions } from './SortOptions';
import { TemplateBasedColumn } from '../../../types';

const StyledTableHeaderCellWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledTableHeader = styled('div')<{ $withSorting: boolean }>`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  width: ${({ $withSorting }) => ($withSorting ? 'calc(100% - 25px)' : '100%')};
`;

interface TemplateBasedTableHeadProps {
  columns: TemplateBasedColumn[];
  sorting: UseSorting;
}

export const TemplateBasedTableHead: FC<TemplateBasedTableHeadProps> = ({
  columns,
  sorting,
}) => (
  <TableHead className="table-head">
    <TableRow className="table-head__first-row">
      {columns.map(({ id, headerText, width, sortKey }) => (
        <TableCell
          key={id}
          style={{ width }}
          title={getTranslatedValue(headerText)}
        >
          <StyledTableHeaderCellWrapper>
            <StyledTableHeader $withSorting={!!sortKey}>
              {getTranslatedValue(headerText)}
            </StyledTableHeader>
            {sortKey && <SortOptions sortKey={sortKey} {...sorting} />}
          </StyledTableHeaderCellWrapper>
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);
