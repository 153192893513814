import {
  AdminColumn,
  Filters,
  FilterTypeEnum,
  GlobalFilterType,
} from '../../types';

export const getInitialFilters = (
  columns: AdminColumn[] = [],
  globalFilters: GlobalFilterType[] = []
): Filters => {
  const filters = columns.reduce<Filters>((acc, col) => {
    if (Array.isArray(col.filtersConfig)) {
      col.filtersConfig.forEach((filterConfig) => {
        const { type } = filterConfig;

        if (type === FilterTypeEnum.DATE_RANGE) {
          acc[filterConfig.startFilterKey] =
            filterConfig.startDefaultValue || '';
          acc[filterConfig.endFilterKey] = filterConfig.endDefaultValue || '';
          return;
        }

        const { defaultValue, filterKey } = filterConfig;

        if (type === FilterTypeEnum.CHECKBOX) {
          acc[filterKey] =
            typeof defaultValue === 'boolean' || defaultValue === null
              ? defaultValue
              : null;
        } else if (
          type === FilterTypeEnum.TEXT ||
          type === FilterTypeEnum.DATE
        ) {
          acc[filterKey] = defaultValue || '';
        } else if (type === FilterTypeEnum.MULTISELECT) {
          acc[filterKey] = defaultValue || [];
        } else if (
          type === FilterTypeEnum.SINGLESELECT &&
          defaultValue !== undefined
        ) {
          acc[filterKey] = defaultValue;
        }
      });
    }
    return acc;
  }, {});

  if (Array.isArray(globalFilters)) {
    globalFilters.forEach(({ type, filterKey, defaultValue }) => {
      if (
        type === FilterTypeEnum.SINGLESELECT &&
        Array.isArray(defaultValue) &&
        defaultValue.length > 0
      ) {
        const [val] = defaultValue;
        filters[filterKey] = val;
      }
    });
  }

  return filters;
};
