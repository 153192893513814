import { alpha, styled } from '@mui/material';
import MuiTabs, { TabsProps as MuiTabsProps } from '@mui/material/Tabs';
import { DistributiveOmit } from '@mui/types';
import React, { ChangeEvent, FC, ReactNode } from 'react';

import { BLACK, PRIMARY, WHITE } from 'config/appColors';

import { Tab } from '../../atoms/Tab';
import { ExtendedTabItem } from '../../organisms/Tabs/types';

interface TabNavProps extends DistributiveOmit<MuiTabsProps, 'onChange'> {
  items: ExtendedTabItem[];
  selectedIndex: number;
  onChange: (event: ChangeEvent<unknown>, tabIndex: number) => void;
  tabNavChildComponent?: ReactNode;
}

const StyledTabNavContainer = styled('div')`
  display: flex;
`;

const StyledMuiTabs = styled(MuiTabs)`
  position: relative;
  box-shadow: inset 0 -1px 0 ${alpha(BLACK, 0.25)};
  background: ${WHITE};
  border-radius: 4px 4px 0 0;
  padding: 16px 16px 0;
  width: 100%;

  .MuiTabs-indicator {
    z-index: 2;
    background: ${PRIMARY.MAIN};
  }

  .MuiTabScrollButton-root svg {
    font-size: 2.3rem;
  }
`;

const StyledNavChildContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
  box-shadow: inset 0 -1px 0 ${alpha(BLACK, 0.25)};
`;

export const TabNav: FC<TabNavProps> = ({
  items,
  selectedIndex,
  onChange,
  tabNavChildComponent,
  ...restProps
}) => (
  <StyledTabNavContainer>
    <StyledMuiTabs
      variant="scrollable"
      scrollButtons="auto"
      value={selectedIndex}
      onChange={onChange}
      {...restProps}
    >
      {items.map(({ label, customLabel, disabled, navId, panelId }) => (
        <Tab
          label={customLabel?.(label) ?? label}
          disabled={disabled}
          key={navId}
          id={navId}
          aria-controls={panelId}
        />
      ))}
    </StyledMuiTabs>
    {tabNavChildComponent && (
      <StyledNavChildContainer>{tabNavChildComponent}</StyledNavChildContainer>
    )}
  </StyledTabNavContainer>
);

export default TabNav;
