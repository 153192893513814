import React from 'react';

import { StyledLink } from 'components/atoms/StyledLink';
import { yiiUrls } from 'config/yiiUrls';

import { AdminTableCell } from '../../../types';

export const SupplierHeadquarterCell: AdminTableCell = ({
  supplierHeadquarter,
}) => (
  <>
    {supplierHeadquarter ? (
      <StyledLink
        target="_blank"
        rel="noopener noreferrer"
        to={{ pathname: yiiUrls.SUPPLIER_DETAILS(supplierHeadquarter.uuid) }}
      >
        (COID: {supplierHeadquarter.coid}) {supplierHeadquarter.name}
      </StyledLink>
    ) : (
      ''
    )}
  </>
);
