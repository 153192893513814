import { styled } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { EmptyState } from 'components/molecules/EmptyState';
import { ErrorState } from 'components/molecules/ErrorState';
import { TableSkeleton } from 'components/molecules/TableSkeleton';
import { usePagination } from 'components/organisms/Table';
import { TablePagination } from 'components/organisms/Table/TablePagination';
import { UNEXPECTED_ERROR } from 'config/constants';
import { LoaderStatusEnum, LoaderStatusWithEmptyEnum } from 'types';

import { useCommoditySearchFilters } from '../../../hooks/useCommoditySearchFilters';
import { useGetSuppliersByCommoditiesQuery } from '../../../state/commoditySearch/api';
import { resetCommoditySearchState } from '../../../state/commoditySearch/slice';
import { CommoditySearchTemplateFilters } from '../../../types';
import { CommoditySearchResultListItem } from '../../atoms/CommoditySearchResultListItem';

const StyledCommodityResultListWrapper = styled('div')`
  .empty-state,
  .error-state {
    background: 0;

    * {
      font-size: 1.2rem;
    }

    h3 {
      margin-left: 12px;
    }

    svg {
      font-size: 2.5rem;
    }
  }
`;

const StyledTableSkeleton = styled(TableSkeleton)`
  span {
    height: 110px;
    border-radius: 5px;

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      height: 24px;
    }
  }
`;

const StyledResultList = styled('ul')`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const StyledResultsItemContainer = styled('li')`
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`;

interface CommoditySearchResultListProps {
  templateFilters: CommoditySearchTemplateFilters;
  logoMapper: Record<string, string>;
}

export const CommoditySearchResultList: FC<CommoditySearchResultListProps> = (
  props
) => {
  const dispatch = useDispatch();
  const { pagination, limit, offset, setTotalRowsAmount } = usePagination();
  const { templateFilters, logoMapper } = props;
  const { URLFilters, filtersReadyToFetch } = useCommoditySearchFilters({
    templateFilters,
    initialCall: true,
  });

  const { suppliersByCommodities, getSuppliersByCommodities, total, status } =
    useGetSuppliersByCommoditiesQuery();

  useEffect(() => {
    setTotalRowsAmount(total);
  }, [setTotalRowsAmount, total]);

  useEffect(
    () => () => {
      // reset state on unmount
      dispatch(resetCommoditySearchState());
    },
    [dispatch]
  );

  useEffect(() => {
    if (filtersReadyToFetch) {
      getSuppliersByCommodities({
        filter: URLFilters,
        limit,
        offset,
      });
    }
  }, [
    URLFilters,
    limit,
    offset,
    filtersReadyToFetch,
    dispatch,
    getSuppliersByCommodities,
  ]);

  const showEmptyState = status === LoaderStatusWithEmptyEnum.EMPTY;
  const showError = status === LoaderStatusEnum.ERROR;
  const showLoader = status === LoaderStatusEnum.LOADING;
  const showResults = status === LoaderStatusEnum.SUCCESS;

  return (
    <StyledCommodityResultListWrapper>
      {showEmptyState && <EmptyState className="empty-state" />}

      {showError && (
        <ErrorState
          className="error-state"
          heading={UNEXPECTED_ERROR} // TODO - it should come from BE
          dense
        />
      )}

      {showLoader && (
        <StyledTableSkeleton showPaginationSkeleton numberOfPlaceholders={5} />
      )}

      {showResults && (
        <>
          <StyledResultList>
            {suppliersByCommodities.map((supplierProps) => (
              <StyledResultsItemContainer key={supplierProps.uuid}>
                <CommoditySearchResultListItem
                  {...supplierProps}
                  logoMapper={logoMapper}
                />
              </StyledResultsItemContainer>
            ))}
          </StyledResultList>
          <TablePagination outsideOfTable {...pagination} />
        </>
      )}
    </StyledCommodityResultListWrapper>
  );
};
