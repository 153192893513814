import { styled } from '@mui/material';
import clsx from 'clsx';
import React, { FC } from 'react';

import { Skeleton } from 'components/atoms/Skeleton';
import { generateId } from 'utils/generateId';

const StyledSkeleton = styled(Skeleton)`
  height: 42px;
  border-radius: 4px;
  margin: 10px 0 10px 0;
`;

const PaginationSkeleton = styled(StyledSkeleton)`
  width: 20%;
  margin-left: auto;
`;

interface TableSkeletonProps {
  showPaginationSkeleton?: boolean;
  className?: string;
  numberOfPlaceholders?: number;
}

export const TableSkeleton: FC<TableSkeletonProps> = (props) => {
  const { showPaginationSkeleton, className, numberOfPlaceholders = 3 } = props;
  const finalClassName = clsx(['skeleton-container', className]);

  return (
    <div className={finalClassName}>
      {Array.from({ length: numberOfPlaceholders }).map((_, index) => (
        <StyledSkeleton key={generateId('skeleton', index)} />
      ))}
      {showPaginationSkeleton && <PaginationSkeleton />}
    </div>
  );
};
