import { isAfter, isBefore, isEqual } from 'date-fns';

import { getDateFromString } from './getDateFromString';

const formatToDate = (date: string | Date): Date =>
  date instanceof Date ? date : (getDateFromString(date) as Date);

export const isDateInRange = (
  dateToCheck: string | Date,
  startDate: string | Date,
  endDate: string | Date
): boolean => {
  const formattedDateToCheck = formatToDate(dateToCheck);
  const formattedStartDate = formatToDate(startDate);
  const formattedEndDate = formatToDate(endDate);

  return (
    (isAfter(formattedDateToCheck, formattedStartDate) &&
      isBefore(formattedDateToCheck, formattedEndDate)) ||
    isEqual(formattedDateToCheck, formattedStartDate) ||
    isEqual(formattedDateToCheck, formattedEndDate)
  );
};

export const isEqualOrBefore = (
  date: Date | number,
  dateToCompare: Date | number
): boolean => isEqual(date, dateToCompare) || isBefore(date, dateToCompare);

export const isEqualOrAfter = (
  date: Date | number,
  dateToCompare: Date | number
): boolean => isEqual(date, dateToCompare) || isAfter(date, dateToCompare);
