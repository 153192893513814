import { styled, Typography } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { usePagination } from 'components/organisms/Table';
import { TablePagination } from 'components/organisms/Table/TablePagination';
import { SPANISH_GRAY } from 'config/appColors';

import { AllDataGlobalFilter } from './AllDataGlobalFilter';
import { AllDataTableBody } from './AllDataTableBody';
import { AllDataTableButtons } from './AllDataTableButtons';
import { AllDataTableHead } from './AllDataTableHead';
import { AllDataTableTemplateProvider } from './AllDataTableTemplateProvider';
import { AllDataTableApiVariant } from '../../../config/allDataApiConfig';
import { ALL_AUDITS_HEADER_HEIGHT } from '../../../config/constants';
import { useFiltersAndSorting } from '../../../hooks/useFiltersAndSorting';
import { useGetAdminDataListQuery } from '../../../state/allDataList/api';
import {
  increaseSearchCounter,
  resetAllDataListState,
} from '../../../state/allDataList/slice';
import { AdminColumn, GlobalFilterType } from '../../../types';
import { AdminTableTemplate } from '../../templates/AdminTableTemplate';

const StyledHeaderContainer = styled('div')`
  height: ${ALL_AUDITS_HEADER_HEIGHT}px;
  margin: 0 4.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledHeading = styled(Typography)`
  font-size: 2.4rem;
  line-height: 2rem;
  letter-spacing: 0.018rem;
  font-weight: 400;
  color: ${SPANISH_GRAY};
  margin-right: auto;
`;

interface RawAllDataTableProps {
  columns: AdminColumn[];
  variant: AllDataTableApiVariant;
  globalFilter?: GlobalFilterType[];
}

const RawAllDataTable: FC<RawAllDataTableProps> = ({
  columns,
  globalFilter,
  variant,
}) => {
  const [t] = useTranslation('components');
  const heading = t(variant === 'audits' ? 'All Audits' : 'All Certificates');
  const {
    pagination,
    limit,
    offset,
    setTotalRowsAmount,
    resetPaginationState,
  } = usePagination({
    enabledQueryParams: true,
    initialRowsPerPage: 50,
  });
  const dispatch = useDispatch();

  const {
    URLFilters,
    URLSortState,
    filtersReadyToFetch,
    setFilterValue,
    filters,
  } = useFiltersAndSorting({
    resetPaginationState,
    columns,
    variant,
    initialCall: true,
    globalFilter,
  });

  const { dataList, total, status, getAllData } = useGetAdminDataListQuery();

  useEffect(() => {
    setTotalRowsAmount(total);
  }, [setTotalRowsAmount, total]);

  useEffect(
    () => () => {
      // reset state on unmount
      dispatch(resetAllDataListState());
    },
    [dispatch]
  );

  useEffect(() => {
    if (filtersReadyToFetch) {
      getAllData(
        {
          filter: URLFilters,
          limit,
          offset,
          ...(URLSortState
            ? { sort: `${URLSortState.key}:${URLSortState.direction}` }
            : {}),
          variant,
        },
        true
      );
      dispatch(increaseSearchCounter(variant));
    }
  }, [
    URLFilters,
    URLSortState,
    limit,
    offset,
    filtersReadyToFetch,
    dispatch,
    getAllData,
    variant,
  ]);

  return (
    <div>
      <StyledHeaderContainer>
        <StyledHeading variant="h3">{heading}</StyledHeading>
        {globalFilter && (
          <AllDataGlobalFilter
            filters={filters}
            globalFilter={globalFilter}
            setFilterValue={setFilterValue}
          />
        )}
        <AllDataTableButtons variant={variant} />
      </StyledHeaderContainer>
      <AdminTableTemplate
        thead={<AllDataTableHead columns={columns} variant={variant} />}
        tbody={<AllDataTableBody data={dataList} columns={columns} />}
        tfoot={<TablePagination {...pagination} outsideOfTable />}
        status={status}
      />
    </div>
  );
};

interface AllDataTableProps {
  variant: AllDataTableApiVariant;
}

export const AllDataTable: FC<AllDataTableProps> = ({ variant }) => (
  <AllDataTableTemplateProvider
    variant={variant}
    table={(template) => <RawAllDataTable variant={variant} {...template} />}
  />
);
