import { styled } from '@mui/material';
import { groupBy } from 'lodash';
import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledTreeItem } from 'components/molecules/TreePickerMultiSelect/TreePickerItem';
import { TreePickerMultiSelect } from 'components/molecules/TreePickerMultiSelect/TreePickerMultiSelect';
import { getTranslatedValue } from 'utils/getTranslatedValue';

import { RISK_TYPES } from '../../../config/constants';
import {
  MultiSelectTreePickerFilter,
  RiskOption,
  UseCommoditySearchFilters,
} from '../../../types';

const StyledRiskGroupLabel = styled('div')`
  margin: 0 0 5px 10px;
  font-weight: 500;

  &:not(:first-of-type) {
    margin-top: 10px;
  }
`;

type RiskPickerFilterProps = UseCommoditySearchFilters &
  MultiSelectTreePickerFilter<RiskOption>;

interface ListItemProps {
  risk: RiskOption;
  renderLabel: (risk: RiskOption) => string;
}

const ListItem: FC<ListItemProps> = ({ risk, renderLabel }) => {
  const { children, uuid } = risk;

  return (
    <StyledTreeItem itemId={uuid} label={renderLabel(risk)}>
      {children.map((item) => (
        <ListItem key={item.uuid} risk={item} renderLabel={renderLabel} />
      ))}
    </StyledTreeItem>
  );
};

export const RiskPickerFilter: FC<RiskPickerFilterProps> = (props) => {
  const [t] = useTranslation('supplier');

  const {
    setFilterValue,
    removeValueFromFilterList,
    filterKey,
    filters,
    labelText,
    options,
  } = props;
  const value = filters[filterKey];
  const risksByRiskType = groupBy(options, ({ riskType }) => riskType);

  const renderLabel = (risk: RiskOption) =>
    `${risk.topical} - ${getTranslatedValue(risk.topic)}`;

  return (
    <TreePickerMultiSelect
      items={options}
      renderLabel={renderLabel}
      onChange={(val) => {
        setFilterValue({ filterKey, value: val });
      }}
      onDelete={(valueToDelete) => {
        removeValueFromFilterList({ filterKey, value: valueToDelete });
      }}
      formLabel={getTranslatedValue(labelText)}
      searchPlaceholder={t('Filter by risk name')}
      value={value}
    >
      {Object.values(RISK_TYPES).map(({ id, heading }) => (
        <Fragment key={id}>
          <StyledRiskGroupLabel>
            {getTranslatedValue(heading)}
          </StyledRiskGroupLabel>

          {risksByRiskType[id]?.map((risk) => (
            <ListItem key={risk.uuid} risk={risk} renderLabel={renderLabel} />
          ))}
        </Fragment>
      ))}
    </TreePickerMultiSelect>
  );
};
