import {
  CommoditySearchTemplateFilters,
  Filters,
  SuppliersByCommoditiesFilterEnum,
} from '../../types';

export const getInitialCommoditySearchFilters = (
  filters: CommoditySearchTemplateFilters = []
): Filters =>
  filters.reduce<Filters>((acc, filterConfig) => {
    const { type, filterKey } = filterConfig;

    if (type === SuppliersByCommoditiesFilterEnum.MULTISELECT) {
      const { defaultValue } = filterConfig;
      acc[filterKey] = defaultValue || [];
    } else if (
      type === SuppliersByCommoditiesFilterEnum.MULTISELECT_TREE_PICKER
    ) {
      acc[filterKey] = [];
    }

    return acc;
  }, {});
