import { SwapVert } from '@mui/icons-material';
import {
  alpha,
  css,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  styled,
} from '@mui/material';
import React, { FC, MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SortAsc, SortDesc } from 'assets/icons';
import { SortDirectionEnum } from 'components/organisms/Table/types';
import { UseSorting } from 'components/organisms/Table/useSorting';
import { BLUE_LOBSTER, TEXT, WHITE } from 'config/appColors';
import { Nullable } from 'types';

const StyledIconButton = styled(IconButton)<{ $withBackground: boolean }>`
  && {
    background: ${({ $withBackground }) =>
      $withBackground ? alpha(WHITE, 0.25) : 'transparent'};
  }

  border-radius: 3px;
  padding: 3px;

  svg {
    fill: white;
    font-size: 1.8rem;
  }
`;

const commonMenuItemCss = css`
  color: ${TEXT.PRIMARY};
  width: 150px;
  font-size: 1.6rem;
  font-weight: 400;
`;

const StyledMenu = styled(Menu)`
  .MuiMenu-list {
    padding: 0;
  }

  .MuiDivider-root {
    margin: 0;
  }
`;

const StyledSortHeader = styled(MenuItem)`
  height: 36px;
  ${commonMenuItemCss};

  &:hover {
    background-color: ${WHITE};
    cursor: default;
  }
`;

const StyledSortOption = styled(MenuItem)<{ $isSelected: boolean }>`
  height: 30px;
  display: flex;
  gap: 10px;
  ${commonMenuItemCss}

  && {
    background-color: ${({ $isSelected }) =>
      $isSelected ? alpha(BLUE_LOBSTER, 0.08) : ''};
  }
`;

const StyledSortReset = styled(MenuItem)`
  ${commonMenuItemCss};
  height: 36px;
  text-align: center;
  display: inline-block;
  width: 100%;
  text-transform: lowercase;
`;

interface SortOptionsProps extends UseSorting {
  sortKey: string;
}

export const SortOptions: FC<SortOptionsProps> = ({
  onSortClick,
  resetSortState,
  sortKey,
  sortState,
}) => {
  const [t] = useTranslation('components');
  const [anchorEl, setAnchorEl] = useState<Nullable<HTMLElement>>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick =
    (key: string, direction: SortDirectionEnum) => () => {
      onSortClick({ key, direction });
      handleClose();
    };

  const isAnyValueSelected = sortKey === sortState?.key;

  const isSelected = (direction: SortDirectionEnum) =>
    isAnyValueSelected && sortState?.direction === direction;

  return (
    <>
      <StyledIconButton
        onClick={handleClick}
        $withBackground={isAnyValueSelected}
      >
        <SwapVert />
      </StyledIconButton>
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <StyledSortHeader>{t('Sort')}</StyledSortHeader>
        <StyledSortOption
          $isSelected={isSelected(SortDirectionEnum.ASC)}
          onClick={handleOptionClick(sortKey, SortDirectionEnum.ASC)}
        >
          <SortDesc /> A-Z
        </StyledSortOption>
        <StyledSortOption
          $isSelected={isSelected(SortDirectionEnum.DESC)}
          onClick={handleOptionClick(sortKey, SortDirectionEnum.DESC)}
        >
          <SortAsc /> Z-A
        </StyledSortOption>
        <Divider />
        <StyledSortReset
          onClick={() => {
            resetSortState();
            handleClose();
          }}
        >
          {t('Reset')}
        </StyledSortReset>
      </StyledMenu>
    </>
  );
};
