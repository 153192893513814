import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import TemplateAuditModal from 'components/templates/TemplateAuditModal';
import { selectUserIsAdmin } from 'domains/user/state/auth/slice';
import { LoaderStatusEnum, ModalProps, StartEndDates } from 'types';

import {
  resetDueDate,
  selectAdminActionComment,
  selectDueDateLoading,
} from '../../../state/dueDate/slice';
import { ResetDueDateParams } from '../../../state/dueDate/types';
import { AuditAppointmentBaseModal } from '../AuditAppointmentBaseModal';
import { ResetDueDateModalContent } from '../ResetDueDateModalContent';

interface ResetDueDateModalProps extends ModalProps {
  appointmentId: string;
  dateRange: StartEndDates;
}

export const ResetDueDateModal: FC<ResetDueDateModalProps> = (props) => {
  const { open, handleClose, appointmentId, dateRange } = props;
  const [t] = useTranslation('auditAppointment');
  const dispatch = useDispatch();
  const loadingStatus = useSelector(selectDueDateLoading);
  const selectedAdminActionComment = useSelector(selectAdminActionComment);
  const [isAdminInfoValid, setIsAdminInfoValid] = useState(false);
  const isAdmin = useSelector(selectUserIsAdmin);

  const nextAction = () => {
    if (appointmentId) {
      const data: ResetDueDateParams = {
        appointmentId,
        actionComment:
          isAdminInfoValid && selectedAdminActionComment
            ? selectedAdminActionComment
            : undefined,
      };
      dispatch(resetDueDate(data));
    }
  };

  return (
    <AuditAppointmentBaseModal open={open}>
      <TemplateAuditModal
        title={t('Reset Due Date')}
        contentComponent={
          <ResetDueDateModalContent
            dateRange={dateRange}
            setIsAdminInfoValid={setIsAdminInfoValid}
            isAdmin={isAdmin}
          />
        }
        closeAction={handleClose}
        prevAction={handleClose}
        nextAction={nextAction}
        noPrevArrow
        noNextArrow
        prevLabel={t('Cancel')}
        nextLabel={t('Reset', { ns: 'components' })}
        prevColor="modalPrevButton"
        nextColor="error"
        disableNext={isAdmin && !isAdminInfoValid}
        nextButtonLoading={loadingStatus === LoaderStatusEnum.LOADING}
        hidePrevButton={loadingStatus === LoaderStatusEnum.LOADING}
      />
    </AuditAppointmentBaseModal>
  );
};
