import { combineReducers } from 'redux';

import commoditySearchReducer from './commoditySearch/slice';
import detailsReducer from './details/slice';
import detailsAddressesReducer from './detailsAddresses/slice';
import detailsContactsReducer from './detailsContacts/slice';

export const supplierReducer = combineReducers({
  details: detailsReducer,
  detailsAddresses: detailsAddressesReducer,
  detailsContacts: detailsContactsReducer,
  commoditySearch: commoditySearchReducer,
});
