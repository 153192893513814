import { getReactEnvVar } from 'utils/getReactEnvVar';

import { LANG_PARAM } from './languages';

const baseUrl = getReactEnvVar('YII_URL');
const baseAdminUrl = getReactEnvVar('YII_ADMIN_URL');

export const yiiUrls = {
  AUDIT_APPOINTMENT_DETAILS: (
    supplierUuid: string,
    auditAppointmentUuid: string
  ): string =>
    `${baseUrl}/supplier/detail?id=${supplierUuid.toUpperCase()}&activeTab=diary&auditAppointmentUuid=${auditAppointmentUuid}`,
  AUDIT_DETAILS: (supplierUuid: string, auditUuid: string): string =>
    `${baseUrl}/supplier/detail?id=${supplierUuid.toUpperCase()}&activeTab=audits&auditUuid=${auditUuid}`,
  AUDIT_DETAILS_COMMODITY_TAB: (
    supplierUuid: string,
    auditUuid: string
  ): string =>
    `${baseUrl}/supplier/detail?id=${supplierUuid.toUpperCase()}&activeTab=audits&auditUuid=${auditUuid}&innerTabName=tab&innerTabValue=commodity-list`,
  CERTIFICATE_DETAILS: (
    supplierUuid: string,
    certificateUuid: string
  ): string =>
    `${baseUrl}/supplier/detail?id=${supplierUuid.toUpperCase()}&activeTab=certificates&certificateUuid=${certificateUuid}`,
  LOGOUT: `${baseUrl}/site/logout`,
  SUPPLIER_DETAILS: (supplierUuid: string): string =>
    `${baseUrl}/supplier/detail?id=${supplierUuid.toUpperCase()}`,
  SUPPORT: `https://www.ifs-certification.com/${LANG_PARAM}/contact`,
  UPDATE_CB_COMPANY: (certificationBodyUuid: string): string =>
    `${baseAdminUrl}/company/update-cb-company?id=${certificationBodyUuid.toUpperCase()}`,
};
