import FoodLogo from 'assets/ifs-food-logo.svg';
import HPCLogo from 'assets/ifs-hpc-logo.svg';
import LogisticsLogo from 'assets/ifs-logistics-logo.svg';
import { GeneralDetails, Logo } from 'types';

import { isFullMock } from '../environments';

enum ModuleIdEnum {
  FOOD_7 = 'food_7',
  LOGISTICS = 'logistics',
  HPC = 'hpc',
}

export const getMockLogo = (id: string): string => {
  switch (id.toLowerCase()) {
    case ModuleIdEnum.FOOD_7:
      return FoodLogo;
    case ModuleIdEnum.LOGISTICS:
      return LogisticsLogo;
    case ModuleIdEnum.HPC:
      return HPCLogo;
    default:
      return FoodLogo;
  }
};

interface ItemWithPossibleCustomLogo {
  module: GeneralDetails;
  tags?: string[];
}

export const getModuleLogoSrc = (
  item: ItemWithPossibleCustomLogo,
  logoResolver: Logo[] = []
): string => {
  const {
    module: { id: moduleId },
    tags = [],
  } = item;
  const filteredResolver = logoResolver.filter((i) => i.type === 'hasTag'); // currently supports only 'hasTag' condition
  const customFileName = filteredResolver.find((logoItem) =>
    tags.includes(logoItem.value)
  );
  const fileName = customFileName?.logo || 'logo.svg';

  return isFullMock
    ? getMockLogo(moduleId)
    : `/api/module-management/assets/images/${moduleId}/${fileName}`;
};
