import { useCallback } from 'react';

import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';
import {
  ExtendedLoaderStatusEnum,
  LoaderStatusEnum,
  LoaderStatusWithEmptyEnum,
} from 'types';

import {
  SuppliersByCommoditiesParams,
  SuppliersByCommoditiesResponse,
  SuppliersByCommoditiesTemplateResponse,
} from './types';
import endpoints from '../../config/endpoints';

const commoditySearchApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getSuppliersByCommodities: build.query<
      SuppliersByCommoditiesResponse,
      SuppliersByCommoditiesParams
    >({
      query: (params) => ({
        url: endpoints.SUPPLIERS.COMMODITY_SEARCH.LIST,
        params,
      }),
      providesTags: ['SUPPLIERS_BY_COMMODITIES'],
      keepUnusedDataFor: 10,
    }),
    getSuppliersByCommoditiesTemplate: build.query<
      SuppliersByCommoditiesTemplateResponse,
      void
    >({
      query: () => ({
        url: endpoints.SUPPLIERS.COMMODITY_SEARCH.TEMPLATE,
      }),
      providesTags: ['SUPPLIERS_BY_COMMODITIES_TEMPLATE'],
      keepUnusedDataFor: 10,
    }),
  }),
});

export const useGetSuppliersByCommoditiesQuery = () => {
  const [trigger, query] =
    commoditySearchApi.useLazyGetSuppliersByCommoditiesQuery();
  let status: ExtendedLoaderStatusEnum = useRTKQueryStatus(query);
  const error = useFormattedError(query);
  const suppliersByCommodities = query?.data?.suppliers || [];

  if (
    status === LoaderStatusEnum.SUCCESS &&
    suppliersByCommodities.length === 0
  ) {
    status = LoaderStatusWithEmptyEnum.EMPTY;
  }

  const getSuppliersByCommodities = useCallback(
    async (params: SuppliersByCommoditiesParams, preferCacheValue = true) =>
      trigger(params, preferCacheValue),
    [trigger]
  );

  return {
    ...query,
    suppliersByCommodities,
    getSuppliersByCommodities,
    total: query.data?.total || 0,
    error,
    status,
  };
};

export const useGetSuppliersByCommoditiesTemplateQuery = () => {
  const query = commoditySearchApi.useGetSuppliersByCommoditiesTemplateQuery();
  const status = useRTKQueryStatus(query);
  const error = useFormattedError(query);

  return {
    ...query,
    commoditySearchTemplate: query?.data?.variables,
    error,
    status,
  };
};
