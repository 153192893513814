import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useModalCommonRequiredFields } from '../../../hooks/useModalCommonRequiredFields';
import { useModalForm } from '../../../hooks/useModalForm';
import { useModalFormFieldsAreValid } from '../../../hooks/useModalFormFieldsAreValid';
import { useTranslatedSelectOptions } from '../../../hooks/useTranslatedSelectOptions';
import { useGetCertificateDetailsQuery } from '../../../state/certificateDetails/api';
import { useUpdateLockReasonMutation } from '../../../state/certificateLock/api';
import { selectCertificateDetailsUpdateUuid } from '../../../state/modal/slice';
import { CertificateDetailsModalEnum } from '../../../state/modal/types';
import { CertificateModalCommonProps } from '../../../types';
import { StyledCustomHeading } from '../../atoms/StyledCustomHeading';
import {
  CertificateDetailsModalBase,
  NextAction,
} from '../../templates/CertificateDetailsModalBase';
import { LockCertificateForm } from '../LockCertificateForm';

type UpdateLockReasonModalProps = CertificateModalCommonProps;

export const UpdateLockReasonModal: FC<UpdateLockReasonModalProps> = (
  props
) => {
  const { certificateUuid } = props;
  const [t] = useTranslation('certificateDetails');
  const { isLoading, error, updateLockReason } = useUpdateLockReasonMutation();
  const { certificateDetails: { ceid } = {} } = useGetCertificateDetailsQuery({
    certificateUuid,
  });
  const isFormValid = useModalFormFieldsAreValid();
  const type = CertificateDetailsModalEnum.UPDATE_LOCK_REASON;
  const fieldValues = useModalCommonRequiredFields();
  const actionReasonOptions = useTranslatedSelectOptions({
    type,
    collectionName: 'actionReasons',
  });
  const lockUuid = useSelector(selectCertificateDetailsUpdateUuid) || '';
  const { setActionInfoValue } = useModalForm();

  useEffect(() => {
    // initially select 'email send' checkbox
    setActionInfoValue('emailSend', true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const nextAction: NextAction = (openSnackbar, closeModal) => {
    updateLockReason({
      certificateUuid,
      lockUuid,
      ...fieldValues,
    }).then(() => {
      openSnackbar(t('Lock reason updated.'), 'success');
      closeModal();
    });
  };

  return (
    <CertificateDetailsModalBase
      title={t('Update lock reason')}
      nextAction={nextAction}
      nextLabel={t('Update')}
      disableNext={!isFormValid}
      isLoading={isLoading}
      nextColor="info"
    >
      <LockCertificateForm
        certificateUuid={certificateUuid}
        errors={error}
        customHeading={
          ceid && (
            <StyledCustomHeading $variant="regular">
              {t('Update lock reason for CEID {{ceid}}', {
                ceid,
              })}
            </StyledCustomHeading>
          )
        }
        actionReasonOptions={actionReasonOptions}
        showEmailCheckbox
        isUpdate
      />
    </CertificateDetailsModalBase>
  );
};
