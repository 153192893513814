import { alpha, Paper, styled } from '@mui/material';
import React, { FC } from 'react';

import Button from 'components/atoms/Button';
import { Heading } from 'components/atoms/Heading';
import { BLACK, TEXT } from 'config/appColors';
import { getTranslatedValue } from 'utils/getTranslatedValue';

import { Filter } from './Filter';
import { useCommoditySearchFilters } from '../../../hooks/useCommoditySearchFilters';
import { SuppliersByCommoditiesTemplateResponse } from '../../../state/commoditySearch/types';
import { CommoditySearchTemplateFilters } from '../../../types';

const StyledWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledTitle = styled(Heading)`
  font-weight: 600;
  color: ${TEXT.PRIMARY};
  padding: 20px;
  border-bottom: 1px solid ${alpha(BLACK, 0.25)};

  && {
    font-size: 1.6rem;
  }
`;

const StyledFilterContainer = styled('div')`
  padding: 25px 20px 0;
`;

const StyledFilterRow = styled('div')`
  margin-bottom: 25px;
`;

const StyledButtonContainer = styled('div')`
  display: flex;
  margin-top: auto;
  padding: 20px;
  justify-content: space-between;
  border-top: 1px solid ${alpha(BLACK, 0.25)};
`;

const StyledResetButton = styled(Button)`
  padding: 6px 10px;
  color: ${TEXT.PRIMARY};
`;

interface CommoditySearchFiltersProps {
  className?: string;
  commoditySearchTemplate?: SuppliersByCommoditiesTemplateResponse['variables'];
  templateFilters: CommoditySearchTemplateFilters;
}

export const CommoditySearchFilters: FC<CommoditySearchFiltersProps> = (
  props
) => {
  const { className, commoditySearchTemplate, templateFilters } = props;

  const { filterHeader, resetBtnText, searchBtnText } =
    commoditySearchTemplate || {};

  const filterMethods = useCommoditySearchFilters({
    templateFilters,
  });
  const { resetFilters, updateURLFilters } = filterMethods;

  if (templateFilters.length === 0) {
    return null;
  }

  return (
    <Paper className={className}>
      <StyledWrapper>
        <StyledTitle variant="h3">
          {getTranslatedValue(filterHeader)}
        </StyledTitle>
        <StyledFilterContainer>
          {templateFilters.map(({ id, ...filterProps }) => (
            <StyledFilterRow key={id}>
              <Filter id={id} {...filterProps} {...filterMethods} />
            </StyledFilterRow>
          ))}
        </StyledFilterContainer>
        <StyledButtonContainer>
          <StyledResetButton variant="text" color="info" onClick={resetFilters}>
            {getTranslatedValue(resetBtnText)}
          </StyledResetButton>
          <Button variant="contained" color="info" onClick={updateURLFilters}>
            {getTranslatedValue(searchBtnText)}
          </Button>
        </StyledButtonContainer>
      </StyledWrapper>
    </Paper>
  );
};
