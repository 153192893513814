import { styled } from '@mui/material';
import React, { FC } from 'react';

import { TablePagination } from 'components/organisms/Table/TablePagination';
import { PaginationProps } from 'components/organisms/Table/types';
import { UseSorting } from 'components/organisms/Table/useSorting';
import { TEXT } from 'config/appColors';
import { AdminTableTemplate } from 'domains/adminReports/components/templates/AdminTableTemplate';
import { ExtendedLoaderStatusEnum } from 'types';

import { TemplateBasedTableBody } from './TemplateBasedTableBody';
import { TemplateBasedTableHead } from './TemplateBasedTableHead';
import { TemplateBasedColumn } from '../../../types';

const StyledAdminTableTemplate = styled(AdminTableTemplate)`
  && {
    height: 100%;
    max-height: 60vh;
    margin: 0 -25px -25px -25px;
    width: calc(100% + 50px);
  }

  tbody * {
    color: ${TEXT.PRIMARY};
  }

  .empty-state,
  .error-state {
    height: 35vh;
  }
`;

interface TemplateBasedTableProps {
  columns: TemplateBasedColumn[];
  data: Record<string, unknown>[];
  status: ExtendedLoaderStatusEnum;
  pagination: PaginationProps;
  sorting: UseSorting;
}

export const TemplateBasedTable: FC<TemplateBasedTableProps> = ({
  columns,
  data,
  pagination,
  status,
  sorting,
}) => (
  <StyledAdminTableTemplate
    thead={<TemplateBasedTableHead columns={columns} sorting={sorting} />}
    tbody={<TemplateBasedTableBody columns={columns} data={data} />}
    tfoot={<TablePagination {...pagination} outsideOfTable />}
    status={status}
  />
);
