/* eslint-disable react/destructuring-assignment */
import { styled } from '@mui/material';
import React, { FC } from 'react';

import { MultiSelect } from 'components/atoms/MultiSelect';
import { TEXT } from 'config/appColors';
import { StyledTextField } from 'domains/adminReports/components/atoms/StyledTextField';
import { getOptionsByRef } from 'domains/adminReports/config/localOptions';
import { convertRawOptionsToOptions } from 'domains/adminReports/utils/convertRawOptionsToOptions';
import { isArrayOfString } from 'types';
import { getTranslatedValue } from 'utils/getTranslatedValue';

import {
  MultiSelectFilter as MultiSelectFilterType,
  UseCommoditySearchFilters,
} from '../../../types';

const StyledMultiSelectFilterContainer = styled('div')`
  .MuiAutocomplete-input.MuiInputBase-input {
    padding-left: 12px;
  }
`;
const StyledLabel = styled('label')`
  font-weight: 500;
  color: ${TEXT.PRIMARY};
`;

type MultiSelectFilterProps = MultiSelectFilterType &
  UseCommoditySearchFilters & {
    className?: string;
  };

export const MultiSelectFilter: FC<MultiSelectFilterProps> = (props) => {
  const {
    removeValueFromFilterList,
    setFilterValue,
    filterKey,
    filters,
    className,
    labelText,
  } = props;

  const options =
    'options' in props ? props.options : getOptionsByRef(props.optionsRef);
  const value = filters[filterKey];
  const valueToDisplay = isArrayOfString(value) ? value : [];
  const optionsToDisplay = convertRawOptionsToOptions(options);
  const inputLabel = `${filterKey}-multiselect`;

  return (
    <StyledMultiSelectFilterContainer className={className}>
      <StyledLabel htmlFor={inputLabel}>
        {getTranslatedValue(labelText)}
      </StyledLabel>
      <MultiSelect
        renderInput={(params) => (
          <StyledTextField {...params} placeholder="---" />
        )}
        onChange={(newValue) => {
          setFilterValue({ filterKey, value: newValue });
        }}
        onDelete={(valueToDelete) => {
          removeValueFromFilterList({ filterKey, value: valueToDelete });
        }}
        value={valueToDisplay}
        options={optionsToDisplay}
        id={inputLabel}
      />
    </StyledMultiSelectFilterContainer>
  );
};
