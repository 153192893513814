import { TableRow } from '@mui/material';
import React, { FC } from 'react';

import { TemplateBasedTableCell } from './TemplateBasedTableCell';
import { TemplateBasedColumn } from '../../../types';

interface TemplateBasedTableRowProps {
  columns: TemplateBasedColumn[];
  item: Record<string, unknown>;
}

export const TemplateBasedTableRow: FC<TemplateBasedTableRowProps> = ({
  columns,
  item,
}) => (
  <TableRow>
    {columns.map((column) => (
      <TemplateBasedTableCell key={column.id} item={item} {...column} />
    ))}
  </TableRow>
);
