import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';
import {
  ExtendedLoaderStatusEnum,
  LoaderStatusEnum,
  LoaderStatusWithEmptyEnum,
} from 'types';

import { CommoditiesParams, CommoditiesResponse } from './types';
import endpoints from '../../config/endpoints';

const commoditiesApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getCommodities: build.query<CommoditiesResponse, CommoditiesParams>({
      query: ({ auditUuid, ...params }) => ({
        url: endpoints.COMMODITIES.LIST(auditUuid),
        params,
      }),
      providesTags: ['AUDIT_COMMODITIES'],
      keepUnusedDataFor: 10,
    }),
  }),
});

export const useGetCommoditiesQuery = (params: CommoditiesParams) => {
  const query = commoditiesApi.useGetCommoditiesQuery(params);
  let status: ExtendedLoaderStatusEnum = useRTKQueryStatus(query, true);
  const error = useFormattedError(query);

  if (status === LoaderStatusEnum.SUCCESS && query?.data?.items.length === 0) {
    status = LoaderStatusWithEmptyEnum.EMPTY;
  }

  return {
    ...query,
    commodities: query.data?.items || [],
    total: query.data?.total || 0,
    error,
    status,
  };
};
