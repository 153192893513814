import React from 'react';

import { StyledLink } from 'components/atoms/StyledLink';
import { yiiUrls } from 'config/yiiUrls';

import { AdminTableCell } from '../../../types';

export const CertificationBodyCell: AdminTableCell = ({
  certificationBody,
}) => (
  <StyledLink
    target="_blank"
    rel="noopener noreferrer"
    to={{
      pathname: yiiUrls.UPDATE_CB_COMPANY(certificationBody.uuid),
    }}
  >
    {certificationBody.cbid && <>(CBID: {certificationBody.cbid})</>}{' '}
    {certificationBody.name}
  </StyledLink>
);
