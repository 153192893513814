/* eslint-disable react/destructuring-assignment */
import { styled } from '@mui/material';
import React from 'react';

import { ResultOutput } from 'components/atoms/AttributeRow';
import { Logger } from 'components/atoms/Logger';
import { Result, WithUndefined } from 'types';

import {
  AdminColumnSettings,
  AdminTableCell,
  isAdminAudit,
  isAdminCertificate,
} from '../../../types';

const StyledContainer = styled('div')`
  font-weight: 400;
`;

export const ResultCell: AdminTableCell = (props) => {
  let result: WithUndefined<Result>;
  let type = 'default';
  let resultMapperByType: WithUndefined<AdminColumnSettings>;

  if (isAdminCertificate(props)) {
    result = props.auditResult;
    type = props.sourceType.id || type;
    resultMapperByType = props.settings?.resultMapperBySourceType;
  }

  if (isAdminAudit(props)) {
    result = props.result;
    type = props.type.id || type;
    resultMapperByType = props.settings?.resultMapperByAuditType;
  }

  if (!result || !resultMapperByType) {
    return null;
  }

  const variantsMap = resultMapperByType[type]
    ? resultMapperByType[type]
    : resultMapperByType.default;

  if (!variantsMap) {
    return (
      <Logger
        message={`System couldn't find the following type: ${type} in the settings`}
      />
    );
  }

  const variant = variantsMap[result?.overall?.id]
    ? variantsMap[result?.overall?.id]
    : variantsMap?.default;

  if (!variant) {
    return (
      <Logger
        message={`System couldn't find value for the following result id: ${
          result?.overall?.id || 'default'
        } in the resultMapper for the following type: ${type}`}
      />
    );
  }

  return (
    <StyledContainer>
      <ResultOutput result={result} variant={variant} />
    </StyledContainer>
  );
};
