import {
  CertificationBody,
  CommonPaginationParams,
  RawOption,
  TranslatedText,
} from 'types';

import { CommodityOption, RiskOption } from './domain';
/**
 * PL4FE-434
 * supplierId - remove this key
 */

export interface Params<StatusEnum> extends CommonPaginationParams {
  status: StatusEnum[];
  supplierId?: string; // uuid
}

export interface UseDraftTrigger {
  isCbModalOpen: boolean;
  showButton: boolean;
  handleUploadProcess: () => void;
  closeCbModal: () => void;
  handleCbNextAction: (cb: CertificationBody) => void;
}

export enum SuppliersByCommoditiesFilterEnum {
  MULTISELECT = 'MULTISELECT',
  MULTISELECT_TREE_PICKER = 'MULTISELECT_TREE_PICKER',
}

export type MultiSelectFilter =
  | {
      type: SuppliersByCommoditiesFilterEnum.MULTISELECT;
      defaultValue?: string[];
      filterKey: string;
      id: string;
      labelText: TranslatedText;
    } & (
      | {
          options: RawOption[];
        }
      | {
          optionsRef: string;
        }
    );

export type MultiSelectTreePickerFilter<
  OptionType extends CommodityOption | RiskOption
> = {
  type: SuppliersByCommoditiesFilterEnum.MULTISELECT_TREE_PICKER;
  filterKey: string;
  id: string;
  labelText: TranslatedText;
  options: OptionType[];
};

export type CommoditySearchFilter<
  OptionType extends CommodityOption | RiskOption
> = MultiSelectFilter | MultiSelectTreePickerFilter<OptionType>;

export type FilterType = string[];

export type Filters = Record<string, FilterType>;

export type Payload<T = FilterType> = {
  filterKey: string;
  value: T;
};

export interface UseCommoditySearchFilters {
  filters: Filters;
  URLFilters: Filters;
  removeValueFromFilterList: (payload: Payload<string>) => void;
  setFilterValue: (payload: Payload | Payload<string[]>) => void;
  resetFilters: () => void;
  filtersReadyToFetch: boolean;
  updateURLFilters: () => void;
}

export type CommoditySearchTemplateFilters = (
  | CommoditySearchFilter<RiskOption>
  | CommoditySearchFilter<CommodityOption>
)[];
