import { css, styled, TableCell } from '@mui/material';
import { get } from 'lodash';
import React, { FC } from 'react';

import { TemplateBasedTableCellComponent } from './TemplateBasedTableCellComponent';
import { TemplateBasedColumn } from '../../../types';

interface StyledTableCellProps {
  $width?: string;
  $backgroundColor?: string;
}

const StyledTableCell = styled(TableCell)<StyledTableCellProps>`
  width: ${({ $width }) => $width};

  ${({ $backgroundColor }) =>
    $backgroundColor &&
    css`
      background-color: ${$backgroundColor};
      && {
        vertical-align: middle;
      }
      text-align: center;
    `}
`;

const getBackgroundColor = (
  item: Record<string, unknown>,
  cellContent: TemplateBasedColumn['cellContent'],
  backgroundColorMapper?: Record<string, string>
) => {
  let backgroundColor = '';
  // cellContent?.[0]?.dataRef is template driven - it's established that first element controls background color
  // we support only one color per cell
  const value = get(item, cellContent?.[0]?.dataRef || '');

  if (backgroundColorMapper && typeof value === 'string') {
    backgroundColor = backgroundColorMapper[value];
  }

  return backgroundColor;
};

interface TemplateBasedTableCellProps extends TemplateBasedColumn {
  item: Record<string, unknown>;
}

export const TemplateBasedTableCell: FC<TemplateBasedTableCellProps> = ({
  id,
  cellContent,
  width,
  backgroundColorMapper,
  item,
}) => {
  const backgroundColor = getBackgroundColor(
    item,
    cellContent,
    backgroundColorMapper
  );

  return (
    <StyledTableCell key={id} $width={width} $backgroundColor={backgroundColor}>
      {cellContent.map((cellContentItem) => (
        <TemplateBasedTableCellComponent
          key={cellContentItem.dataRef}
          cellContent={cellContentItem}
          item={item}
        />
      ))}
    </StyledTableCell>
  );
};
