import { isObject } from 'lodash';

import { SaveProcessingStepsParams } from 'components/molecules/WidgetProcessingStepsGeneral/ProcessingStepsWidget';
import { SaveProductScopesParams } from 'components/molecules/WidgetProductScopesGeneral/ProductScopeWidget';
import { SaveTechScopesParams } from 'components/molecules/WidgetTechScopesGeneral/TechScopeWidget';
import { CertificateDetails } from 'domains/certificateDetails/types';
import { Address } from 'domains/supplier/types';
import {
  Audit,
  AuditorParticipant,
  CertificateLock,
  Document,
  ExceptionalCircumstance,
  GeneralDetails,
  Message,
  NestedGeneralDetails,
  Supplier,
} from 'types';

interface SupplierHeadquarter extends Omit<Address, 'type'> {
  coid: string;
  legalForm: string;
}

interface ExtendedSupplier extends Supplier {
  legalForm: string;
  street: string;
  city: string;
  zipCode: string;
  countryCode?: string;
}

export interface AdminAuditDynamicFields {
  scopeDescriptionAudit?: string;
  scopeDescriptionAudit_en?: string;
  exclusionsDescription?: string;
  exclusionsDescription_en?: string;
  fsmaDescription?: string;
  fsmaDescription_en?: string;
  partlyOutsourcedProcessesDescription?: string;
  partlyOutsourcedProcessesDescription_en?: string;
  outsourcedProcessesProductsDescription?: string;
  outsourcedProcessesProductsDescription_en?: string;
  hkzLogo?: boolean;
}

type AdminAuditCertificate = CertificateDetails &
  AdminAuditDynamicFields & {
    lockHistory?: CertificateLock[];
    isGroupCertification?: boolean;
    scopeDescriptionCertificate?: string;
    productScopesCertificate: SaveProductScopesParams['productScopesAudit'];
    techScopesCertificate: SaveTechScopesParams['techScopesAudit'];
    processingStepsCertificate: SaveProcessingStepsParams['processingStepsCertificate'];
    messages?: Message[];
    exceptionalCircumstances: ExceptionalCircumstance[];
  };

export interface Scope {
  id?: string;
  name?: string;
  branchKey?: string;
}

interface AdminAuditorParticipant extends AuditorParticipant {
  scopes?: {
    productScopes?: Scope[];
    techScopes?: Scope[];
  };
}

export interface AdminAudit
  extends Omit<
    Audit & AdminAuditDynamicFields,
    'ceid' | 'supplier' | 'certificate'
  > {
  recordType: 'audit';
  uploadedAt?: string;
  supplier: ExtendedSupplier;
  supplierHeadquarter?: SupplierHeadquarter;
  moduleFamily?: GeneralDetails;
  executionMode: GeneralDetails;
  appointmentUuid?: string;
  auditors?: AdminAuditorParticipant[];
  productScopesAudit?: NestedGeneralDetails[];
  techScopesAudit?: NestedGeneralDetails[];
  processingStepsAudit?: NestedGeneralDetails[];
  tags?: string[];
  documents?: Document[];
  messages?: Message[];
  certificate?: AdminAuditCertificate;
  exceededReason?: string;
  exceededReasonDescription?: string;
  reductionReason?: string;
  reductionReasonDescription?: string;
}

export const isAdminAudit = (adminAudit: unknown): adminAudit is AdminAudit =>
  isObject(adminAudit) &&
  'recordType' in adminAudit &&
  adminAudit.recordType === 'audit';

export enum SupportedMessageEnum {
  TOO_LATE_UPLOAD = 'too-late-upload',
  AUDIT_TIME_EXCEEDED = 'audit-time-exceeded',
  AUDIT_TIME_NOT_REACHED = 'audit-time-not-reached',
}

export interface AdminCertificate
  extends Omit<
    CertificateDetails,
    'status' | 'supplier' | 'supplierHeadquarter' | 'type'
  > {
  recordType: 'certificate';
  status: GeneralDetails;
  sourceType: GeneralDetails;
  certificateType?: GeneralDetails;
  lockHistory?: CertificateLock[];
  isGroupCertification?: boolean;
  scopeDescriptionCertificate?: string;
  scopeDescriptionCertificate_en?: string;
  productScopesCertificate: NestedGeneralDetails[];
  techScopesCertificate: NestedGeneralDetails[];
  processingStepsCertificate: NestedGeneralDetails[];
  messages?: Message[];
  exceptionalCircumstances: ExceptionalCircumstance[];
  exclusionsDescription?: string;
  fsmaDescription?: string;
  documents?: Document[];
  supplier: ExtendedSupplier;
  supplierHeadquarter?: SupplierHeadquarter;
  createdAt?: string;
  initialIssuedAt?: string;
  lastModifiedAt?: string;
  hasExtension?: boolean;
  lastModifiedAtCertificateCoreData?: string;
}

export const isAdminCertificate = (
  adminCertificate: unknown
): adminCertificate is AdminCertificate =>
  isObject(adminCertificate) &&
  'recordType' in adminCertificate &&
  adminCertificate.recordType === 'certificate';

export type AdminRecord = AdminAudit | AdminCertificate;
