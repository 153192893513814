import { styled } from '@mui/material';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import { Logger } from 'components/atoms/Logger';
import { Tabs } from 'components/organisms/Tabs';
import { TabItem } from 'components/organisms/Tabs/types';
import { WHITE } from 'config/appColors';
import { getTranslatedValue } from 'utils/getTranslatedValue';

import { useGetAuditDetailsTemplateQuery } from '../../../state/auditDetailsTemplate/api';
import {
  AuditActionsEnum,
  AuditDetailsTabEnum,
  isAccessesTab,
  isAuditorParticipantsTab,
  isCertificateTab,
  isCommoditiesTab,
  isDetailsTab,
  isDocumentsTab,
  isLogsTab,
  isReleasesTab,
  isRiskMitigationPlanTab,
} from '../../../types';
import { TabItemLabel } from '../../atoms/TabItemLabel';
import { AuditDetailsActionsDropdown } from '../../molecules/AuditDetailsActionsDropdown';
import { AccessesTab } from '../AccessesTab';
import { AuditorParticipantsTab } from '../AuditorParticipantsTab';
import { CertificateTab } from '../CertificateTab';
import { CommoditiesTab } from '../CommoditiesTab';
import { DetailsTab } from '../DetailsTab';
import { DocumentsTab } from '../DocumentsTab';
import { LogsTab } from '../LogsTab';
import { ReleasesTab } from '../ReleasesTab';
import { RiskMitigationPlanTab } from '../RiskMitigationPlanTab';

const getDetailsTab = (tab: unknown, auditUuid: string) =>
  isDetailsTab(tab) ? (
    <DetailsTab {...tab.props} auditUuid={auditUuid}>
      {tab.children}
    </DetailsTab>
  ) : null;

const getCertificateTab = (tab: unknown, auditUuid: string) =>
  isCertificateTab(tab) ? (
    <CertificateTab {...tab.props} auditUuid={auditUuid}>
      {tab.children}
    </CertificateTab>
  ) : null;

const getDocumentsTab = (tab: unknown, auditUuid: string) =>
  isDocumentsTab(tab) ? (
    <DocumentsTab {...tab.props} auditUuid={auditUuid} />
  ) : null;

const getAuditorParticipantsTab = (tab: unknown, auditUuid: string) =>
  isAuditorParticipantsTab(tab) ? (
    <AuditorParticipantsTab {...tab.props} auditUuid={auditUuid} />
  ) : null;

const getReleasesTab = (tab: unknown, auditUuid: string) =>
  isReleasesTab(tab) ? (
    <ReleasesTab {...tab.props} auditUuid={auditUuid} />
  ) : null;

const getLogsTab = (tab: unknown, auditUuid: string) =>
  isLogsTab(tab) ? <LogsTab {...tab.props} auditUuid={auditUuid} /> : null;

const getAccessesTab = (tab: unknown, auditUuid: string) =>
  isAccessesTab(tab) ? (
    <AccessesTab {...tab.props} auditUuid={auditUuid} />
  ) : null;

const getRiskMitigationPlanTab = (tab: unknown, auditUuid: string) =>
  isRiskMitigationPlanTab(tab) ? (
    <RiskMitigationPlanTab {...tab.props} auditUuid={auditUuid} />
  ) : null;

const getCommoditiesTab = (tab: unknown, auditUuid: string) =>
  isCommoditiesTab(tab) ? (
    <CommoditiesTab {...tab.props} auditUuid={auditUuid} />
  ) : null;

const AUDIT_TABS = {
  [AuditDetailsTabEnum.DETAILS]: getDetailsTab,
  [AuditDetailsTabEnum.CERTIFICATE]: getCertificateTab,
  [AuditDetailsTabEnum.DOCUMENTS]: getDocumentsTab,
  [AuditDetailsTabEnum.AUDITOR_PARTICIPANTS]: getAuditorParticipantsTab,
  [AuditDetailsTabEnum.RELEASES]: getReleasesTab,
  [AuditDetailsTabEnum.LOGS]: getLogsTab,
  [AuditDetailsTabEnum.ACCESSES]: getAccessesTab,
  [AuditDetailsTabEnum.RISK_MITIGATION_PLAN]: getRiskMitigationPlanTab,
  [AuditDetailsTabEnum.COMMODITIES]: getCommoditiesTab,
};

const StyledTabContentWrapper = styled('div')`
  min-height: 500px;
  padding: 25px;
  background: ${WHITE};
`;

interface MappedAuditTabsProps {
  auditDetailsActions: AuditActionsEnum[];
}

export const MappedAuditTabs: FC<MappedAuditTabsProps> = ({
  auditDetailsActions,
}) => {
  const { supplierId, auditUuid } =
    useParams<{ supplierId: string; auditUuid: string }>(); // @ToDo - unify type with AuditDetailsURLParams
  const { auditDetailsTemplate } = useGetAuditDetailsTemplateQuery(auditUuid);

  const actionButton = (
    <AuditDetailsActionsDropdown
      auditUuid={auditUuid}
      supplierId={supplierId}
    />
  );

  const tabs: TabItem[] = auditDetailsTemplate
    ? auditDetailsTemplate.template.children
        .filter(({ props }) =>
          'requiredAction' in props
            ? auditDetailsActions.includes(props.requiredAction)
            : true
        )
        .map((tab) => ({
          label: getTranslatedValue(tab.props.name),
          customLabel: (label) => (
            <TabItemLabel
              label={label}
              auditUuid={auditUuid}
              tabId={tab.props.id}
            />
          ),
          content: (
            <StyledTabContentWrapper>
              {AUDIT_TABS[tab.props.id] ? (
                AUDIT_TABS[tab.props.id](tab, auditUuid)
              ) : (
                <Logger
                  message={`${tab.props.id} not supported tab id, please check spelling`}
                />
              )}
            </StyledTabContentWrapper>
          ),
        }))
    : [];

  return (
    <>
      {!!auditDetailsTemplate && (
        <Tabs
          items={tabs}
          tabNavChildComponent={actionButton}
          routeParamName="tab"
        />
      )}
    </>
  );
};
