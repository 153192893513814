import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { QueryParams } from 'utils/queryParams';

import {
  removeValueFromFilterList,
  resetCommoditySearchState,
  selectAreFiltersReadyToFetch,
  selectFilters,
  setFiltersReadyToFetch,
  setFilterValue,
  updateFiltersState,
} from '../state/commoditySearch/slice';
import {
  CommoditySearchTemplateFilters,
  Filters,
  UseCommoditySearchFilters,
} from '../types';
import { getInitialCommoditySearchFilters } from '../utils/getInitialCommoditySearchFilters';

interface UseCommoditySearchFiltersProps {
  templateFilters: CommoditySearchTemplateFilters;
  resetPaginationState?: () => void;
  initialCall?: boolean;
}

export const useCommoditySearchFilters = ({
  templateFilters = [],
  resetPaginationState,
  initialCall = false,
}: UseCommoditySearchFiltersProps): UseCommoditySearchFilters => {
  const { replace } = useHistory();
  const d = useDispatch();
  const filtersReadyToFetch = useSelector(selectAreFiltersReadyToFetch);
  const UIFilters = useSelector(selectFilters);

  const { getParam, parseParams, updateQueryParams, stringifyParams } =
    QueryParams;

  const initialFilters = getInitialCommoditySearchFilters(templateFilters);
  const filtersInURL = getParam('filters');
  const parsedFilters = useMemo(
    () => parseParams(filtersInURL || '') as Filters,
    [parseParams, filtersInURL]
  );

  const updateURL = useCallback(
    (value: Record<string, unknown>, mergeParams = true) =>
      replace({
        search: updateQueryParams(value, mergeParams),
      }),
    [replace, updateQueryParams]
  );

  const resetFilters = () => {
    d(resetCommoditySearchState());
    updateURL(
      {
        filters: stringifyParams(initialFilters),
      },
      false
    );
    resetPaginationState?.();
  };

  const updateURLFilters = () => {
    updateURL({
      filters: stringifyParams(UIFilters),
    });
  };

  useEffect(() => {
    if (!initialCall) {
      return;
    }

    if (!filtersReadyToFetch) {
      // set initial values

      if (!filtersInURL) {
        // if param doesn't exist in URL set initial params
        updateURL({
          filters: stringifyParams(initialFilters),
        });
        d(
          updateFiltersState({
            filters: initialFilters,
          })
        );
      } else {
        d(
          updateFiltersState({
            filters: parsedFilters,
          })
        );
      }

      d(setFiltersReadyToFetch());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersReadyToFetch, filtersInURL]);

  return {
    filters: UIFilters, // filters selected on UI, filters = URLFilters if user clicked "Search" button
    URLFilters: parsedFilters, // filters in URL, served to API request
    setFilterValue: (payload) => d(setFilterValue(payload)),
    removeValueFromFilterList: (payload) =>
      d(removeValueFromFilterList(payload)),
    resetFilters,
    filtersReadyToFetch,
    updateURLFilters,
  };
};
