import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Paper, styled } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Heading } from 'components/atoms/Heading';
import { ImageLoader } from 'components/atoms/ImageLoader';
import { StyledLink } from 'components/atoms/StyledLink';
import { TEXT } from 'config/appColors';
import { yiiUrls } from 'config/yiiUrls';
import { TEMPORARY_MODULES } from 'domains/auditAppointment/config/temporalConstants';
import { isFullMock } from 'utils/environments';
import { getMockLogo } from 'utils/getModuleLogoSrc';
import { shortenText } from 'utils/shortenText';

import { SupplierWithCommodity } from '../../../state/commoditySearch/types';

const StyledPaper = styled(Paper)`
  display: flex;
  padding: 17px 12px;
`;
const StyledSupplierName = styled(Heading)`
  line-height: 3.2rem;
  font-weight: 400;

  && {
    font-size: 2.4rem;
  }

  a {
    color: ${TEXT.PRIMARY};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;
const StyledCOID = styled('span')`
  font-size: 1.6rem;
  color: ${TEXT.SECONDARY_LIGHT};
`;
const StyledLogoContainer = styled('div')`
  display: flex;
  margin: 20px 0 0 10px;
  gap: 0 10px;
`;
const StyledFoundCommodities = styled('div')`
  margin-left: auto;
  text-align: right;

  p {
    margin: 9px 0;
    font-weight: 500;
    color: ${TEXT.SECONDARY_LIGHT};
  }

  a {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    svg {
      margin-left: 5px;
    }
  }
`;

interface CommoditySearchResultListItemProps extends SupplierWithCommodity {
  className?: string;
  logoMapper: Record<string, string>;
}

export const CommoditySearchResultListItem: FC<CommoditySearchResultListItemProps> =
  ({
    name,
    coid,
    commodityAudits,
    uuid: supplierUuid,
    className,
    logoMapper,
  }) => {
    const [t] = useTranslation('supplier');
    const NAME_MAX_LENGTH = 40;
    const isNameTooLong = name.length > NAME_MAX_LENGTH;

    return (
      <StyledPaper className={className}>
        <div>
          <StyledSupplierName variant="h4">
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to={{
                pathname: yiiUrls.SUPPLIER_DETAILS(supplierUuid),
              }}
            >
              {shortenText(name, NAME_MAX_LENGTH)}
              {!isNameTooLong && (
                <StyledCOID>
                  {' '}
                  ({t('COID')}: {coid})
                </StyledCOID>
              )}
            </Link>
          </StyledSupplierName>
          <StyledLogoContainer>
            {commodityAudits.map(({ uuid, moduleId }) => (
              <ImageLoader
                key={uuid}
                height="35px"
                src={isFullMock ? getMockLogo(moduleId) : logoMapper[moduleId]}
              />
            ))}
          </StyledLogoContainer>
        </div>

        <StyledFoundCommodities>
          <p>{t('Found Commodities')}</p>
          {commodityAudits.map(
            ({ uuid, moduleId, numberOfMatchingCommodities }) => {
              const moduleName = TEMPORARY_MODULES.find(
                ({ value }) => value === moduleId
              )?.label;

              return (
                <StyledLink
                  key={uuid}
                  target="_blank"
                  rel="noopener noreferrer"
                  to={{
                    pathname: yiiUrls.AUDIT_DETAILS_COMMODITY_TAB(
                      supplierUuid,
                      uuid
                    ),
                  }}
                >
                  {moduleName}: {numberOfMatchingCommodities}
                  <OpenInNewIcon />
                </StyledLink>
              );
            }
          )}
        </StyledFoundCommodities>
      </StyledPaper>
    );
  };
