import { styled } from '@mui/material';
import React, { FC } from 'react';

import { Logger } from 'components/atoms/Logger';

import { CommodityPickerFilter } from './CommodityPickerFilter';
import { MultiSelectFilter } from './MultiSelectFilter';
import { RiskPickerFilter } from './RiskPickerFilter';
import {
  CommodityOption,
  CommoditySearchFilter,
  isCommodityOption,
  isRiskOption,
  RiskOption,
  SuppliersByCommoditiesFilterEnum,
  UseCommoditySearchFilters,
} from '../../../types';

const StyledMultiSelectFilter = styled(MultiSelectFilter)`
  .MuiInputBase-root,
  input {
    height: 40px;
  }
`;

type FilterProps = (
  | CommoditySearchFilter<RiskOption>
  | CommoditySearchFilter<CommodityOption>
) &
  UseCommoditySearchFilters;

const isRiskOptionList = (list: unknown[]): list is RiskOption[] =>
  (list as RiskOption[]).every(isRiskOption);

export const Filter: FC<FilterProps> = (props) => {
  const { type } = props;
  const INCORRECT_COMPONENT_MESSAGE =
    'Unrecognized multiselect tree picker option';

  switch (type) {
    case SuppliersByCommoditiesFilterEnum.MULTISELECT: {
      return <StyledMultiSelectFilter {...props} />;
    }
    case SuppliersByCommoditiesFilterEnum.MULTISELECT_TREE_PICKER: {
      const { options } = props;

      if (isRiskOptionList(options)) {
        return <RiskPickerFilter {...props} options={options} />;
      }

      if (options.every(isCommodityOption)) {
        return <CommodityPickerFilter {...props} options={options} />;
      }

      return <Logger message={INCORRECT_COMPONENT_MESSAGE} />;
    }
    default: {
      return <Logger message={INCORRECT_COMPONENT_MESSAGE} />;
    }
  }
};
