import { alpha, styled } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader } from 'components/molecules/Loader';
import { PageHeader } from 'components/molecules/PageHeader';
import TemplatePageDefault from 'components/templates/TemplatePageDefault';
import { BLACK, TEXT, WHITE } from 'config/appColors';
import { CommoditySearchFilters } from 'domains/supplier/components/molecules/CommoditySearchFilters';
import { CommoditySearchResultList } from 'domains/supplier/components/organisms/CommoditySearchResultList';
import { useGetSuppliersByCommoditiesTemplateQuery } from 'domains/supplier/state/commoditySearch/api';

const FILTERS_WIDTH = '27%'; // or 300px

const StyledPageWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

const StyledPageHeaderWrapper = styled('div')`
  background: ${WHITE};
  border-bottom: 1px solid ${alpha(BLACK, 0.25)};
`;

const StyledPageHeader = styled(PageHeader)`
  h2 {
    font-size: 2.4rem;
    line-height: 3.2rem;
    font-weight: 400;
    color: ${TEXT.SECONDARY_LIGHT};
  }
`;

const StyledPageContent = styled('div')`
  width: 100%;
  display: flex;
  padding: 20px;
`;

const StyledCommoditySearchFilters = styled(CommoditySearchFilters)`
  width: ${FILTERS_WIDTH};
  align-self: start;
`;

const StyledResultWrapper = styled('div')`
  width: calc(100% - ${FILTERS_WIDTH});
  padding-left: 20px;
`;

export const WidgetSupplierSearchByCommodity: FC = () => {
  const [t] = useTranslation('supplier');
  const { commoditySearchTemplate, status } =
    useGetSuppliersByCommoditiesTemplateQuery();

  const { filters = {}, logoMapper = {} } = commoditySearchTemplate || {};
  const templateFilters = Object.values(filters);

  return (
    <TemplatePageDefault hidePageHeader hideAside hideHeader hideSupport>
      <StyledPageWrapper>
        <StyledPageHeaderWrapper>
          <StyledPageHeader title={t('Commodity Search')} hideBreadcrumbs />
        </StyledPageHeaderWrapper>
        <Loader status={status} minHeight="300px">
          <StyledPageContent>
            <StyledCommoditySearchFilters
              commoditySearchTemplate={commoditySearchTemplate}
              templateFilters={templateFilters}
            />
            <StyledResultWrapper>
              <CommoditySearchResultList
                templateFilters={templateFilters}
                logoMapper={logoMapper}
              />
            </StyledResultWrapper>
          </StyledPageContent>
        </Loader>
      </StyledPageWrapper>
    </TemplatePageDefault>
  );
};
