import { isObject } from 'lodash';

export const getValuesByRef = (data: unknown, ref: string): unknown => {
  const currentKeys = ref ? ref.split('.') : [];

  function traverse(current: unknown, keys: string[]): unknown {
    // Base case: no more keys, return current value.
    if (keys.length === 0) return current;

    // If current is an array, map over each element with the same keys.
    if (Array.isArray(current)) {
      return current.map((item) => traverse(item, keys));
    }

    const [key, ...rest] = keys;

    if (!isObject(current)) return undefined;

    return traverse(
      key in current ? current[key as keyof typeof current] : undefined,
      rest
    );
  }

  return traverse(data, currentKeys);
};
