import { useSelector } from 'react-redux';

import { selectUserIsAdmin } from 'domains/user/state/auth/slice';
import { FieldIdentifier, SelectOption } from 'types';
import { getTranslatedValue } from 'utils/getTranslatedValue';

import { useGetCertificateDetailsTemplateQuery } from '../state/certificateDetailsTemplate/api';
import { CertificateDetailsTabEnum, CertificateLockHistoryTab } from '../types';

interface UseLockCertificateOptions {
  typeOptions: FieldIdentifier<string>[];
  reasonCategories: SelectOption[];
}

export const useLockCertificateOptions = (
  certificateUuid: string,
  isUpdate: boolean
): UseLockCertificateOptions => {
  const isAdmin = useSelector(selectUserIsAdmin);
  const { certificateDetailsTemplate } =
    useGetCertificateDetailsTemplateQuery(certificateUuid);
  const lockHistoryTemplate =
    certificateDetailsTemplate?.template?.children?.find(
      ({ props: { id } }) => id === CertificateDetailsTabEnum.LOCK_HISTORY
    ) as CertificateLockHistoryTab;

  let typeOptions: FieldIdentifier[] = [];

  if (lockHistoryTemplate?.props?.certificateLockTypeOptions) {
    typeOptions =
      lockHistoryTemplate.props.certificateLockTypeOptions.map(
        ({ text, id, enabled = true }) => ({
          id: id as string,
          name: getTranslatedValue(text),
          enabled: isUpdate ? isAdmin && isUpdate : enabled,
        })
      ) || [];
  }

  let reasonCategories: SelectOption[] = [];

  if (lockHistoryTemplate?.props?.certificateLockReasonCategoryOptions) {
    reasonCategories =
      lockHistoryTemplate.props.certificateLockReasonCategoryOptions.map(
        ({ text, id }) => ({
          label: getTranslatedValue(text),
          value: id as string,
          ariaLabel: getTranslatedValue(text),
        })
      );
  }

  return { typeOptions, reasonCategories };
};
