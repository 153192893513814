import { styled } from '@mui/material';
import React, { ComponentProps, FC } from 'react';

import { Checkbox } from 'components/atoms/Checkbox';
import { WHITE } from 'config/appColors';

const StyledCheckbox = styled(Checkbox)`
  transform: translateX(-2px);
  margin-right: 0;
  margin-top: 3px;
  position: relative;
  z-index: 2;

  .MuiCheckbox-root {
    padding: 0;
    margin: 0;
  }
`;

const StyledContainer = styled('div')`
  position: relative;
`;

const WhiteBackground = styled('div')`
  width: 1.6rem;
  height: 1.6rem;
  position: absolute;
  background-color: ${WHITE};
  top: 0.3rem;
  left: 0.3rem;
`;

export const AdminTableCheckbox: FC<ComponentProps<typeof Checkbox>> = (
  props
) => (
  <StyledContainer>
    <StyledCheckbox {...props} />
    <WhiteBackground />
  </StyledContainer>
);
