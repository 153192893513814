import { styled } from '@mui/material';
import { get } from 'lodash';
import React, { FC } from 'react';

import { isArrayOfString } from 'types';
import { getTranslatedValue } from 'utils/getTranslatedValue';
import { getValuesByRef } from 'utils/getValuesByRef';

import { TemplateBasedColumn } from '../../../types';

const StyledHeader = styled('h4')`
  margin: 0;
  font-weight: 600;
  font-size: 1.6rem;
`;

const StyledList = styled('ul')`
  padding-left: 25px;
  margin: 7px 0;

  li {
    font-weight: 400;
    line-height: 2.4rem;
    font-size: 1.6rem;
  }
`;

const StyledParagraph = styled('p')<{ $isBold?: boolean }>`
  margin: 0;
  font-weight: ${({ $isBold }) => ($isBold ? '600' : '400')};
  font-size: ${({ $isBold }) => ($isBold ? '1.6rem' : '1.4rem')};
`;

interface TemplateBasedTableCellComponentProps {
  cellContent: TemplateBasedColumn['cellContent'][number];
  item: Record<string, unknown>;
}

export const TemplateBasedTableCellComponent: FC<TemplateBasedTableCellComponentProps> =
  ({ cellContent, item }) => {
    const { header, dataRef, dataType, styleTags } = cellContent;
    const headerComponent = header ? (
      <StyledHeader>{getTranslatedValue(header)}</StyledHeader>
    ) : null;

    if (dataType === 'objectArray') {
      const values = getValuesByRef(item, dataRef);

      if (
        !isArrayOfString(values) ||
        (Array.isArray(values) && values.length === 0)
      )
        return null;

      return (
        <div>
          {headerComponent}
          <StyledList>
            {values.map((value) => (
              <li key={value}>{value}</li>
            ))}
          </StyledList>
        </div>
      );
    }

    const value = get(item, dataRef);

    if (typeof value === 'string' || typeof value === 'number') {
      return (
        <div>
          {headerComponent}
          <StyledParagraph $isBold={styleTags?.includes('bold')}>
            {value}
          </StyledParagraph>
        </div>
      );
    }

    return null;
  };
