import { styled } from '@mui/material';
import get from 'lodash/get';
import React, { FC, ReactNode } from 'react';

import { TEXT } from 'config/appColors';
import { Result, ResultDetails, WithUndefined } from 'types';

import { AttributeRowBase } from './AttributeRowBase';
import { AttributeRowCommonProps } from './types';
import { Logger } from '../Logger';

const StyledUnorderedList = styled('ul')`
  padding-left: 25px;
  margin: 0;
`;

interface StyledResultDetailsOutputProps {
  $color: string;
}

const StyledResultDetailsOutput = styled(
  'span'
)<StyledResultDetailsOutputProps>`
  color: ${({ $color }) => $color};
`;

interface ResultDetailsOutputProps {
  resultDetails: ResultDetails;
  withPercentage?: boolean;
  customColor?: string;
}

const ResultDetailsOutput: FC<ResultDetailsOutputProps> = (props) => {
  const { resultDetails, withPercentage = false, customColor } = props;

  return (
    <>
      <StyledResultDetailsOutput $color={customColor || resultDetails.color}>
        {resultDetails.text}
      </StyledResultDetailsOutput>{' '}
      {withPercentage && resultDetails.percentage && (
        <>({resultDetails.percentage}%)</>
      )}
    </>
  );
};

const isResult = (result: unknown): result is Result =>
  typeof result === 'object' &&
  !!result &&
  'overall' in result &&
  'levels' in result;

export type ResultVariant =
  | 'onlyOverallWithPercentage'
  | 'onlyOverallWithoutPercentage'
  | 'overallWithPercentageAndLevelsWithPercentage';

interface ResultOutputProps {
  variant: ResultVariant;
  result: Result;
}

export const ResultOutput: FC<ResultOutputProps> = ({ variant, result }) => {
  const { overall, levels } = result;
  const showResultLevelsOutput = levels && levels.length > 0;

  const componentsMap: Record<ResultVariant, ReactNode> = {
    onlyOverallWithoutPercentage: (
      <ResultDetailsOutput resultDetails={overall} />
    ),
    onlyOverallWithPercentage: (
      <ResultDetailsOutput resultDetails={overall} withPercentage />
    ),
    overallWithPercentageAndLevelsWithPercentage: (
      <>
        <ResultDetailsOutput resultDetails={overall} withPercentage />
        {showResultLevelsOutput && (
          <StyledUnorderedList>
            {levels.map((level) => (
              <li key={`${level.id} ${level.percentage}`}>
                <ResultDetailsOutput
                  resultDetails={level}
                  customColor={TEXT.PRIMARY}
                  withPercentage
                />
              </li>
            ))}
          </StyledUnorderedList>
        )}
      </>
    ),
  };

  return <> {componentsMap[variant]}</>;
};

export interface AttributeRowResultProps extends AttributeRowCommonProps {
  designMapper?: Record<string, ResultVariant>;
  data: unknown;
}

export const AttributeRowResult: FC<AttributeRowResultProps> = (props) => {
  const { labelText, data, dataRef, designMapper } = props;
  const value = get(data, dataRef);
  const showAttributeRow = isResult(value);

  if (!showAttributeRow) {
    return (
      <Logger message="Something went wrong! It might be wrong dataRef in config file or wrong Result data" />
    );
  }

  if (!designMapper) {
    return (
      <Logger message="Something went wrong! The designMapper object is empty - please check config file." />
    );
  }

  const variant: WithUndefined<ResultVariant> = designMapper[value.overall.id];

  if (!variant) {
    return (
      <Logger
        message={`Something went wrong! There is no value in designMapper object for this key: ${value.overall.id}`}
      />
    );
  }

  return (
    <AttributeRowBase
      label={labelText}
      value={<ResultOutput variant={variant} result={value} />}
    />
  );
};
