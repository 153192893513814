import { styled } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Column,
  Table,
  useColumnConfig,
  usePagination,
  useSorting,
} from 'components/organisms/Table';
import { formatDate } from 'utils/dates';

import { accessManagementNs } from '../../../locales/index';
import { useGetAccessesQuery } from '../../../state/accesses/api';
import { AccessesListProps } from '../../../state/accesses/types';
import { Access } from '../../../types';

const StyledTable = styled(Table)`
  table {
    width: 100%;
  }
` as typeof Table;

export const AccessList: FC<AccessesListProps> = ({
  entityUuid,
  resourceName,
}) => {
  const [t] = useTranslation(accessManagementNs);
  const { sortState, sortString, onSortClick } = useSorting();
  const { offset, limit, pagination, setTotalRowsAmount } = usePagination();
  const { error, accesses, total, status } = useGetAccessesQuery({
    resourceName,
    entityUuid,
    offset,
    limit,
    ...(sortString && {
      sort: sortString,
    }),
  });
  const { getColumnConfig } = useColumnConfig('28%');

  useEffect(() => {
    setTotalRowsAmount(total);
  }, [setTotalRowsAmount, total]);

  const accessesColumns: Column<Access>[] = [
    {
      headerName: t('Company (COID)', { ns: 'auditDetails' }),
      customCell: ({ company }) => `${company.name} (${company.coid})`,
      sortKey: 'company.name',
      onSortClick,
      ...getColumnConfig(),
    },
    {
      headerName: t('Company Type'),
      customCell: ({ companyType }) => companyType.name,
      sortKey: 'companyType.name',
      onSortClick,
      ...getColumnConfig(),
    },
    {
      headerName: t('Role', { ns: 'auditDraft' }),
      customCell: ({ role }) => role.name,
      sortKey: 'role.name',
      onSortClick,
      ...getColumnConfig(),
    },
    {
      headerName: t('created at'),
      customCell: ({ createdAt }) => formatDate(createdAt),
      sortKey: 'createdAt',
      onSortClick,
      ...getColumnConfig('15%'),
    },
  ];

  return (
    <StyledTable
      columns={accessesColumns}
      rows={accesses}
      status={status}
      error={error}
      pagination={pagination}
      sortState={sortState}
      dense
    />
  );
};
