import { TableBody } from '@mui/material';
import React, { FC } from 'react';

import { getUniqueValueFrom } from 'utils/getUniqueValueFrom';

import { TemplateBasedTableRow } from './TemplateBasedTableRow';
import { TemplateBasedColumn } from '../../../types';

interface TemplateBasedTableBodyProps {
  data: Record<string, unknown>[];
  columns: TemplateBasedColumn[];
}

export const TemplateBasedTableBody: FC<TemplateBasedTableBodyProps> = ({
  data,
  columns,
}) => (
  <TableBody>
    {data.map((item) => (
      <TemplateBasedTableRow
        key={getUniqueValueFrom(item)}
        item={item}
        columns={columns}
      />
    ))}
  </TableBody>
);
