import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { QueryParams } from 'utils/queryParams';

import { SortStateType } from '../../../components/organisms/Table/types';
import { AllDataTableApiVariant } from '../config/allDataApiConfig';
import {
  removeValueFromFilterList,
  resetAllDataListState,
  selectAreFiltersReadyToFetch,
  selectFilters,
  selectSortState,
  setFiltersReadyToFetch,
  setFilterValue,
  setSortState,
  updateFiltersAndSortState,
} from '../state/allDataList/slice';
import {
  AdminColumn,
  Filters,
  GlobalFilterType,
  UseFiltersAndSorting,
} from '../types';
import { getInitialFilters } from '../utils/getInitialFilters';

interface UseFiltersAndSortingProps {
  resetPaginationState?: () => void;
  columns?: AdminColumn[];
  variant: AllDataTableApiVariant;
  initialCall?: boolean;
  globalFilter?: GlobalFilterType[];
}

export const useFiltersAndSorting = ({
  resetPaginationState,
  columns = [],
  variant,
  initialCall = false,
  globalFilter,
}: UseFiltersAndSortingProps): UseFiltersAndSorting => {
  const { replace, location } = useHistory();
  const d = useDispatch();
  const filtersReadyToFetch = useSelector(
    selectAreFiltersReadyToFetch(variant)
  );
  const UIFilters = useSelector(selectFilters(variant));
  const UISortState = useSelector(selectSortState(variant));

  const { getParam, parseParams, updateQueryParams, stringifyParams } =
    QueryParams;

  const initialFilters = getInitialFilters(columns, globalFilter);
  const filtersInURL = getParam('filters');
  const parsedFilters = useMemo(
    () => parseParams(filtersInURL || '') as Filters,
    [parseParams, filtersInURL]
  );

  const sortStateInURL = getParam('sort');
  const parsedSortState = useMemo(
    () =>
      sortStateInURL ? (parseParams(sortStateInURL) as SortStateType) : null,
    [parseParams, sortStateInURL]
  );

  const updateURL = useCallback(
    (value: Record<string, unknown>, mergeParams = true) =>
      replace({
        search: updateQueryParams(value, mergeParams),
      }),
    [replace, updateQueryParams]
  );

  const resetFiltersAndSorting = () => {
    d(resetAllDataListState());
    updateURL(
      {
        filters: stringifyParams(initialFilters),
      },
      false
    );
    resetPaginationState?.();
  };

  const updateURLFiltersAndSorting = () => {
    updateURL({
      filters: stringifyParams(UIFilters),
      ...(UISortState && { sort: stringifyParams(UISortState) }),
    });
  };

  useEffect(() => {
    if (!initialCall) {
      return;
    }

    if (!filtersReadyToFetch) {
      // set initial values
      if (
        !filtersInURL ||
        (filtersInURL &&
          location.search.includes('ceid') &&
          variant === 'audits') ||
        (filtersInURL &&
          location.search.includes('auid') &&
          variant === 'certificates')
      ) {
        // if param doesn't exist in URL set initial params
        updateURL({
          filters: stringifyParams(initialFilters),
        });
        d(
          updateFiltersAndSortState({
            filters: initialFilters,
            sortState: null,
            variant,
          })
        );
      } else {
        d(
          updateFiltersAndSortState({
            filters: parsedFilters,
            sortState: parsedSortState,
            variant,
          })
        );
      }

      d(setFiltersReadyToFetch(variant));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersReadyToFetch, filtersInURL]);

  return {
    filters: UIFilters, // filters selected on UI, filters = URLFilters if user clicked "Search" button
    URLFilters: parsedFilters, // filters in URL, served to API request
    setFilterValue: (payload) => d(setFilterValue({ ...payload, variant })),
    removeValueFromFilterList: (payload) =>
      d(removeValueFromFilterList({ ...payload, variant })),
    sortState: UISortState, // sortState selected on UI, sortState = URLSortState if user clicked "Search" button
    URLSortState: parsedSortState, // sortState in URL, served to API request
    setSortState: (payload) => d(setSortState({ ...payload, variant })),
    resetFiltersAndSorting,
    filtersReadyToFetch,
    updateURLFiltersAndSorting,
  };
};
