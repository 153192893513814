import React from 'react';

import { formatDate } from 'utils/dates';

import { AdminTableCell, isAdminCertificate } from '../../../types';
import { StyledPlainText } from '../../atoms/StyledPlainText';

export const CertificateValidityCell: AdminTableCell = (props) => {
  const isCertificate = isAdminCertificate(props);
  // eslint-disable-next-line react/destructuring-assignment
  if (!isCertificate || (isCertificate && !props.validity)) {
    return null;
  }

  const {
    validity: { end },
  } = props;

  return <StyledPlainText>{formatDate(end)}</StyledPlainText>;
};
