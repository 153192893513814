import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import { alpha, styled } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/atoms/Button';
import { BLACK } from 'config/appColors';

import { AllDataTableApiVariant } from '../../../config/allDataApiConfig';
import { useFiltersAndSorting } from '../../../hooks/useFiltersAndSorting';

const StyledButtonsContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: 20px;
`;

const StyledColumnsIcon = styled(ViewColumnIcon)`
  font-size: 3.2rem;
  margin-left: 22px;
  color: ${alpha(BLACK, 0.3)};
`;

interface AllDataTableButtonsProps {
  variant: AllDataTableApiVariant;
}

export const AllDataTableButtons: FC<AllDataTableButtonsProps> = ({
  variant,
}) => {
  const [t] = useTranslation('adminReports');

  const { updateURLFiltersAndSorting, resetFiltersAndSorting } =
    useFiltersAndSorting({ variant });

  return (
    <StyledButtonsContainer>
      <Button
        variant="contained"
        color="info"
        size="small"
        onClick={updateURLFiltersAndSorting}
      >
        {t('Search')}
      </Button>
      <Button
        variant="contained"
        color="info"
        size="small"
        onClick={resetFiltersAndSorting}
      >
        {t('Reset filter')}
      </Button>
      <Button variant="contained" color="info" size="small" disabled>
        {t('export', { ns: 'components' })}
      </Button>
      <StyledColumnsIcon />
    </StyledButtonsContainer>
  );
};
