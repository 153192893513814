import { useState } from 'react';

import { Nullable } from 'types';

import { SortStateType } from './types';

export interface UseSorting {
  sortState: Nullable<SortStateType>;
  onSortClick: (sortState: Nullable<SortStateType>) => void;
  resetSortState: () => void;
  sortString?: string;
}

export const useSorting = (): UseSorting => {
  const [sortState, onSortClick] = useState<Nullable<SortStateType>>(null);
  const sortString = sortState
    ? `${sortState.key}:${sortState.direction}`
    : undefined;
  return {
    sortState,
    sortString,
    onSortClick,
    resetSortState: () => onSortClick(null),
  };
};
