import SearchIcon from '@mui/icons-material/Search';
import { ButtonGroup, styled } from '@mui/material';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/atoms/Button';
import { TextField } from 'components/atoms/TextField';

const StyledContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 15px;
`;

interface SearchFilterProps {
  onSearchClick: (searchPhrase: string) => void;
  onResetClick: () => void;
  enableReset: boolean;
}

export const SearchFilter: FC<SearchFilterProps> = ({
  onResetClick,
  onSearchClick,
  enableReset,
}) => {
  const [t] = useTranslation('components');
  const [searchPhrase, setSearchPhrase] = useState('');

  const disabledSearch = searchPhrase.trim().length === 0;
  const disabledReset = disabledSearch && !enableReset;

  return (
    <StyledContainer>
      <TextField
        placeholder="search"
        InputProps={{ endAdornment: <SearchIcon fontSize="large" /> }}
        value={searchPhrase}
        onChange={(e) => setSearchPhrase(e.target.value)}
      />
      <ButtonGroup>
        <Button
          disabled={disabledSearch}
          onClick={() => onSearchClick(searchPhrase)}
          variant="contained"
          color="info"
          size="small"
        >
          {t('Search')}
        </Button>
        <Button
          disabled={disabledReset}
          onClick={() => {
            setSearchPhrase('');
            onResetClick();
          }}
          variant="contained"
          color="info"
          size="small"
        >
          {t('Reset')}
        </Button>
      </ButtonGroup>
    </StyledContainer>
  );
};
