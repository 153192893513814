import { DistributiveOmit } from '@mui/types';
import { isObject } from 'lodash';

import { AuditAppointment as AuditAppointmentWithBlackout } from 'domains/auditAppointment/types';
import { CertificateDetails as AuditCertificateDetails } from 'domains/certificateDetails/types';
import { Nullable, Supplier, TranslatedText } from 'types';

export interface SupplierDetails extends Supplier {
  GLN: string[];
}

export interface Address {
  uuid: string;
  name: string;
  type: string;
  street: string;
  zipCode: string;
  city: string;
  country: string;
}

export interface SupplierContact {
  uuid: string;
  firstname: string;
  lastname: string;
  salutation: string;
  type: string;
  phone: string;
  email: string;
  fax: string;
}

export type AuditAppointment = DistributiveOmit<
  AuditAppointmentWithBlackout,
  'blackoutTimes'
>;

// type for certificateListApi response in CertificatesTab in Supplier Overview
export type CertificateDetails = Omit<
  AuditCertificateDetails,
  | 'issuedAt'
  | 'auditType'
  | 'auditKind'
  | 'announcedRecertificationTimeframe'
  | 'unannouncedRecertificationTimeframe'
  | 'exceptionalCircumstances'
  | 'messages'
  | 'moduleFamily'
>;

export enum CertificateStatusEnum {
  ACTIVE = 'active',
  SUSPENDED = 'suspended',
  WITHDRAWN = 'withdrawn',
  DEPRECATED = 'deprecated',
  EXPIRED = 'expired',
}

export interface CommodityOption {
  uuid: string;
  active: boolean;
  children?: CommodityOption[];
  code: string;
  definition: TranslatedText;
  definitionexcludes: string;
  level: number;
  title: TranslatedText;
}

export interface RiskOption {
  uuid: string;
  children: RiskOption[];
  id: string;
  level: number;
  riskType: string;
  topic: TranslatedText;
  topical: string;
  parentUuid: Nullable<string>;
}

export const isCommodityOption = (option: unknown): option is CommodityOption =>
  isObject(option) && 'code' in option && 'definitionexcludes' in option;

export const isRiskOption = (option: unknown): option is RiskOption =>
  isObject(option) && 'riskType' in option && 'topical' in option;
