import { LANG_PARAM } from 'config/languages';

const endpoints = {
  ACTIONS: (auditUuid: string): string =>
    `/${LANG_PARAM}/audits/${auditUuid}/actions`,
  AUDITOR_PARTICIPANTS_TAB: {
    LIST: (auditUuid: string): string =>
      `/${LANG_PARAM}/audits/${auditUuid}/auditors`,
  },
  COMMODITIES: {
    LIST: (auditUuid: string): string =>
      `/${LANG_PARAM}/audits/${auditUuid}/commodities`,
  },
  DELETE_AUDIT: (auditUuid: string): string =>
    `/${LANG_PARAM}/audits/${auditUuid}`,
  DETAILS: (auditUuid: string): string => `/${LANG_PARAM}/audits/${auditUuid}`,
  DOCUMENTS_TAB: {
    DOWNLOAD: (auditUuid: string, documentUuid: string): string =>
      `/${LANG_PARAM}/audits/${auditUuid}/documents/${documentUuid}`,
    LIST: (auditUuid: string): string =>
      `/${LANG_PARAM}/audits/${auditUuid}/documents`,
  },
  ISSUE_GROUP_CERTIFICATE: (auditUuid: string): string =>
    `/${LANG_PARAM}/audits/${auditUuid}/group-certificate`,
  LOGS_TAB: {
    LIST: (auditUuid: string): string =>
      `/${LANG_PARAM}/audits/${auditUuid}/logs`,
    OLD_LOGS_FILE: (auditUuid: string): string =>
      `/${LANG_PARAM}/audits/${auditUuid}/logs-old-file`,
  },
  RELEASES: {
    COMPANY_LIST: `/${LANG_PARAM}/releases-preflights/company-search`,
    CREATE: `/${LANG_PARAM}/releases`,
    DELETE: (releaseUuid: string): string =>
      `/${LANG_PARAM}/releases/${releaseUuid}`,
    LIST: (auditUuid: string): string =>
      `/${LANG_PARAM}/audits/${auditUuid}/releases`,
    UPDATE: (releaseUuid: string): string =>
      `/${LANG_PARAM}/releases/${releaseUuid}`,
  },
  RISK_MITIGATION_PLAN: {
    LIST: (auditUuid: string): string =>
      `/${LANG_PARAM}/audits/${auditUuid}/commodities-risks-evaluations`,
  },
  TEMPLATE: (auditUuid: string): string =>
    `/${LANG_PARAM}/audits/${auditUuid}/templates/auditDetails`,
};

export default endpoints;
