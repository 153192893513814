import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledTreeItem } from 'components/molecules/TreePickerMultiSelect/TreePickerItem';
import { TreePickerMultiSelect } from 'components/molecules/TreePickerMultiSelect/TreePickerMultiSelect';
import { getTranslatedValue } from 'utils/getTranslatedValue';

import {
  CommodityOption,
  MultiSelectTreePickerFilter,
  UseCommoditySearchFilters,
} from '../../../types';

interface ListItemProps {
  commodity: CommodityOption;
  renderLabel: (commodity: CommodityOption) => string;
}

const ListItem: FC<ListItemProps> = ({ commodity, renderLabel }) => {
  const { children, uuid } = commodity;

  return (
    <StyledTreeItem itemId={uuid} label={renderLabel(commodity)}>
      {children?.map((item) => (
        <ListItem key={item.uuid} commodity={item} renderLabel={renderLabel} />
      ))}
    </StyledTreeItem>
  );
};

type CommodityPickerFilterProps = UseCommoditySearchFilters &
  MultiSelectTreePickerFilter<CommodityOption>;

export const CommodityPickerFilter: FC<CommodityPickerFilterProps> = (
  props
) => {
  const [t] = useTranslation('supplier');

  const {
    setFilterValue,
    removeValueFromFilterList,
    filterKey,
    filters,
    labelText,
    options,
  } = props;
  const value = filters[filterKey];

  const renderLabel = (commodity: CommodityOption) =>
    getTranslatedValue(commodity.title);

  return (
    <TreePickerMultiSelect
      items={options}
      renderLabel={renderLabel}
      onChange={(val) => {
        setFilterValue({ filterKey, value: val });
      }}
      onDelete={(valueToDelete) => {
        removeValueFromFilterList({ filterKey, value: valueToDelete });
      }}
      formLabel={getTranslatedValue(labelText)}
      searchPlaceholder={t('Filter by commodity name')}
      value={value}
    >
      {options.map((commodity) => (
        <ListItem
          key={commodity.uuid}
          commodity={commodity}
          renderLabel={renderLabel}
        />
      ))}
    </TreePickerMultiSelect>
  );
};
