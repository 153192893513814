import { styled } from '@mui/material';
import React, { FC } from 'react';

import { TEXT } from 'config/appColors';
import { getTranslatedValue } from 'utils/getTranslatedValue';

import {
  Filters,
  FilterTypeEnum,
  GlobalFilterType,
  UseFiltersAndSorting,
} from '../../../types';
import { SelectFilterCell as SelectFilter } from '../../molecules/FilterCells/SelectFilterCell';

const StyledContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: -22px;

  & > span {
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 1.2rem;
    color: ${TEXT.PRIMARY};
  }
`;

const StyledSelectFilter = styled(SelectFilter)`
  width: 140px;
`;

interface AllDataGlobalFilterProps {
  globalFilter: GlobalFilterType[];
  filters: Filters;
  setFilterValue: UseFiltersAndSorting['setFilterValue'];
}

export const AllDataGlobalFilter: FC<AllDataGlobalFilterProps> = ({
  filters,
  globalFilter,
  setFilterValue,
}) => {
  if (globalFilter.length === 0) return null;

  return (
    <div>
      {globalFilter.map(
        ({ type, filterKey, defaultValue, options, labelText }) => {
          if (type === FilterTypeEnum.SINGLESELECT) {
            return (
              <StyledContainer key={filterKey}>
                <span>{getTranslatedValue(labelText)}</span>
                <StyledSelectFilter
                  filterKey={filterKey}
                  filters={filters}
                  options={options}
                  setFilterValue={setFilterValue}
                  defaultValue={defaultValue[0]}
                />
              </StyledContainer>
            );
          }

          // any other filter goes here

          return null;
        }
      )}
    </div>
  );
};
