import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';
import {
  ExtendedLoaderStatusEnum,
  LoaderStatusEnum,
  LoaderStatusWithEmptyEnum,
} from 'types';

import { RisksParams, RisksResponse } from './types';
import endpoints from '../../config/endpoints';

const riskMitigationPlanApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAuditRisks: build.query<RisksResponse, RisksParams>({
      query: ({ auditUuid, ...params }) => ({
        url: endpoints.RISK_MITIGATION_PLAN.LIST(auditUuid),
        params,
      }),
      providesTags: ['AUDIT_RISKS'],
      keepUnusedDataFor: 10,
    }),
  }),
});

export const useGetAuditRisksQuery = (params: RisksParams) => {
  const query = riskMitigationPlanApi.useGetAuditRisksQuery(params);
  let status: ExtendedLoaderStatusEnum = useRTKQueryStatus(query, true);
  const error = useFormattedError(query);

  if (status === LoaderStatusEnum.SUCCESS && query?.data?.items.length === 0) {
    status = LoaderStatusWithEmptyEnum.EMPTY;
  }

  return {
    ...query,
    risks: query.data?.items || [],
    total: query.data?.total || 0,
    error,
    status,
  };
};
