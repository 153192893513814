import { FileCopy } from '@mui/icons-material';
import { styled } from '@mui/material';
import React from 'react';

import { useAllDataModal } from '../../../hooks/useAllDataModal';
import { AdminReportsModalEnum } from '../../../state/modal/types';
import { AdminTableCell, isAdminCertificate } from '../../../types';
import { StyledLinkButton as LinkButton } from '../../atoms/StyledLinkButton';

const StyledLinkButton = styled(LinkButton)`
  padding-left: 0;
  margin-top: 10px;
`;

export const CertificateRelatedAuditsCell: AdminTableCell = ({
  t,
  ...restProps
}) => {
  const { openModal } = useAllDataModal(restProps);

  const isCertificate = isAdminCertificate(restProps);
  if (
    !isCertificate ||
    (isCertificate && restProps.sourceType.name !== 'audit')
  ) {
    return null;
  }

  return (
    <StyledLinkButton
      onClick={() => openModal(AdminReportsModalEnum.CERTIFICATE_AUDITS)}
    >
      <FileCopy fontSize="large" />
      {t('show Audits')}
    </StyledLinkButton>
  );
};
