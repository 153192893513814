import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store';

import { CommoditySearchFiltersState } from './types';
import { parentReducerName } from '../../config/constants';
import { FilterType, Payload } from '../../types';

const initialState: CommoditySearchFiltersState = {
  filtersReadyToFetch: false,
  filters: {},
};

const commoditySearchSlice = createSlice({
  initialState,
  name: 'commoditySearch',
  reducers: {
    setFiltersReadyToFetch: (state) => {
      state.filtersReadyToFetch = true;
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
    },
    setFilterValue: (
      state,
      action: PayloadAction<Payload | Payload<string[]>>
    ) => {
      state.filters[action.payload.filterKey] = action.payload.value;
    },
    removeValueFromFilterList: (
      state,
      action: PayloadAction<Payload<string>>
    ) => {
      const filterList = state.filters[action.payload.filterKey];

      if (Array.isArray(filterList)) {
        state.filters[action.payload.filterKey] = filterList.filter(
          (filter) => filter !== action.payload.value
        );
      } else if (action.payload.filterKey in state) {
        // console.warn only for development purposes, we can get rid of it once table is finished
        console.warn(
          "You're trying to remove element of filter list by passing wrong key"
        );
      }
    },
    resetCommoditySearchState: () => initialState,
    updateFiltersState: (
      state,
      action: PayloadAction<Pick<CommoditySearchFiltersState, 'filters'>>
    ) => {
      state.filters = action.payload.filters;
    },
  },
});

export const {
  setFiltersReadyToFetch,
  removeValueFromFilterList,
  resetFilters,
  setFilterValue,
  resetCommoditySearchState,
  updateFiltersState,
} = commoditySearchSlice.actions;

export const selectFilters = (state: RootState): Record<string, FilterType> =>
  state[parentReducerName].commoditySearch.filters;

export const selectAreFiltersReadyToFetch = (state: RootState): boolean =>
  state[parentReducerName].commoditySearch.filtersReadyToFetch;

export default commoditySearchSlice.reducer;
