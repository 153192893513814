import React from 'react';

import { formatDate } from 'utils/dates';

import { AdminTableCell, isAdminCertificate } from '../../../types';
import { StyledPlainText } from '../../atoms/StyledPlainText';

export const InitialIssuedAtCell: AdminTableCell = (props) => {
  if (!isAdminCertificate(props)) {
    return null;
  }

  const { initialIssuedAt } = props;

  return <StyledPlainText>{formatDate(initialIssuedAt)}</StyledPlainText>;
};
