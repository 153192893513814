import { styled } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { createGlobalStyle } from 'styled-components';

import { PageHeader } from 'components/molecules/PageHeader';
import { Tabs } from 'components/organisms/Tabs';
import { TabItem } from 'components/organisms/Tabs/types';
import { LIGHT_GRAY_1, MOSTLY_WHITE_GRAY, TEXT } from 'config/appColors';
import { selectUserCompanyName } from 'domains/user/state/auth/slice';

import { DiaryAuditAppointmentsTab } from '../DiaryAuditAppointmentsTab';
import { DiaryAuditRegistrationsTab } from '../DiaryAuditRegistrationsTab';
// import { DiaryAuditMissingEntriesTab } from '../DiaryAuditMissingEntriesTab';

const GlobalStyle = createGlobalStyle`
  &&&.MuiTabs-root {
    background: transparent !important;
  }
`;

const StyledPageWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

const StyledPageHeaderWrapper = styled('div')``;

const StyledTabContentWrapper = styled('div')`
  min-height: 500px;
  padding: 40px;
  background: ${MOSTLY_WHITE_GRAY};
  border: 1px solid ${LIGHT_GRAY_1};
  border-top: 0;
`;

const StyledPageHeader = styled(PageHeader)`
  h2 {
    font-size: 2.4rem;
    line-height: 3.2rem;
    font-weight: 400;
    color: ${TEXT.PRIMARY};
  }
`;

export const WidgetDiary: FC = () => {
  const [t] = useTranslation('auditAppointment');
  const certificationBodyName = useSelector(selectUserCompanyName);

  const tabs: TabItem[] = [
    {
      label: t('Audit Appointments'),
      content: (
        <StyledTabContentWrapper>
          <DiaryAuditAppointmentsTab />
        </StyledTabContentWrapper>
      ),
    },
    {
      label: t('Audit Registrations'),
      content: (
        <StyledTabContentWrapper>
          <DiaryAuditRegistrationsTab />
        </StyledTabContentWrapper>
      ),
    },
    // Missing entries tab is hidden right now. Please uncomment this code and its import when it should be visible again.
    // {
    //   label: t('Missing Entries'),
    //   content: (
    //     <StyledTabContentWrapper>
    //       <DiaryAuditMissingEntriesTab />
    //     </StyledTabContentWrapper>
    //   ),
    // },
  ];

  return (
    <StyledPageWrapper>
      <GlobalStyle />
      <StyledPageHeaderWrapper>
        <StyledPageHeader
          title={t('Diary', { ns: 'components' })}
          details={certificationBodyName}
          hideBreadcrumbs
        />
      </StyledPageHeaderWrapper>
      <Tabs items={tabs} />
    </StyledPageWrapper>
  );
};
