import { SUPPORTED_CELLS_KEYS } from 'domains/adminReports/config/adminTableColumns';
import { useFormattedError } from 'hooks/useFormattedError';
import { useRTKQueryStatus } from 'hooks/useRTKQueryStatus';
import { apiSlice } from 'store/apiSlice';
import {
  ExtendedLoaderStatusEnum,
  LoaderStatusEnum,
  LoaderStatusWithEmptyEnum,
} from 'types';

import { AllDataTableTemplate } from './types';
import {
  allDataTableApiConfig,
  AllDataTableApiVariant,
} from '../../config/allDataApiConfig';
import { FilterTypeEnum } from '../../types';

const allDataTableTemplateApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAllDataTableTemplate: build.query<
      AllDataTableTemplate['variables'],
      AllDataTableApiVariant
    >({
      query: (variant) => ({
        url: allDataTableApiConfig[variant].templateUrl,
      }),
      providesTags: (result, error, variant) => [
        allDataTableApiConfig[variant].templateTag,
      ],
      keepUnusedDataFor: 60,
      // Response transformation:
      //  - remove unsupported columns - system will not handle them anyway
      //  - simplify response
      transformResponse: (
        response: AllDataTableTemplate
      ): AllDataTableTemplate['variables'] => {
        if (response.variables) {
          return {
            columns: response.variables.columns.filter(({ id }) => {
              const result = SUPPORTED_CELLS_KEYS.includes(id);
              if (!result) {
                console.warn(
                  `System does not support a following cell type: ${id}`
                );
              }
              return result;
            }),
            globalFilter: response.variables.globalFilter?.filter(
              ({ type }) => {
                // we support only single select in global filters for now
                if (type === FilterTypeEnum.SINGLESELECT) {
                  return true;
                }

                console.warn(
                  `System does not support a following global filter type: ${type}`
                );

                return false;
              }
            ),
          };
        }

        return {
          columns: [],
        };
      },
    }),
  }),
});

export const useGetAllDataTableTemplateQuery = (
  variant: AllDataTableApiVariant
) => {
  const query =
    allDataTableTemplateApi.useGetAllDataTableTemplateQuery(variant);

  const error = useFormattedError(query, 'formatToAlerts');
  let status: ExtendedLoaderStatusEnum = useRTKQueryStatus(query);

  if (
    status === LoaderStatusEnum.SUCCESS &&
    (!Array.isArray(query.data?.columns) || query.data?.columns.length === 0)
  ) {
    console.warn(
      `System encountered empty or invalid All ${variant} Table template`
    );
    status = LoaderStatusWithEmptyEnum.EMPTY;
  }

  return {
    ...query,
    template: query.data || { columns: [] },
    status,
    error,
  };
};
