import { Chip, styled } from '@mui/material';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { TEXT } from 'config/appColors';

import { findNodeByUuid, TreeNode, TreePicker } from './TreePicker';

const StyledTreePickerContainer = styled('div')`
  label {
    margin-bottom: 0;
    font-size: 1.4rem;
    line-height: 2.2rem;
    font-weight: 500;
  }

  .MuiOutlinedInput-input {
    color: ${TEXT.SECONDARY_LIGHT};
    caret-color: transparent;
  }

  .MuiInputBase-formControl {
    height: 40px;
    position: relative;

    button {
      width: 19px;
      height: 19px;
      position: absolute;
      top: 50%;
      right: 6px;
      margin-top: -9.5px;

      svg {
        font-size: 2.6rem;
      }
    }
  }
`;

const StyledChipsContainer = styled('div')`
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  .MuiChip-label {
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: 0.016rem;
    color: ${TEXT.PRIMARY};
  }
`;

interface TreePickerMultiSelectProps<T extends TreeNode<T>> {
  items: T[];
  renderLabel: (el: T) => string;
  onChange: (values: string[]) => void;
  formLabel: string;
  searchPlaceholder: string;
  noOptionsFoundLabel?: string;
  filterLabel?: string;
  value?: string[];
  children: ReactNode;
  onDelete: (value: string) => void;
}

export function TreePickerMultiSelect<T extends TreeNode<T>>(
  props: TreePickerMultiSelectProps<T>
): JSX.Element {
  const [t] = useTranslation('components');
  const {
    onChange,
    formLabel,
    searchPlaceholder,
    noOptionsFoundLabel = t('No options found'),
    filterLabel = t('Filter'),
    items,
    renderLabel,
    children,
    value = [],
    onDelete,
  } = props;

  const handleOptionChange = (selectedOption: string) => {
    if (value.includes(selectedOption)) {
      onChange(value.filter((i) => i !== selectedOption));
    } else {
      onChange([...value, selectedOption]);
    }
  };

  const selectedValues = value
    .map((selectedValue) => findNodeByUuid(items, selectedValue))
    .filter((values) => values !== null) as T[];

  return (
    <StyledTreePickerContainer>
      <TreePicker
        multiple
        onBlur={() => {}}
        onChange={handleOptionChange}
        items={items}
        renderLabel={renderLabel}
        renderValue={() => '---'}
        inputPlaceholder="---"
        formLabel={formLabel}
        searchPlaceholder={searchPlaceholder}
        noOptionsFoundLabel={noOptionsFoundLabel || ''}
        filterLabel={filterLabel || ''}
        value={value}
      >
        {children}
      </TreePicker>
      <StyledChipsContainer>
        {selectedValues.map((option) => (
          <Chip
            size="small"
            key={renderLabel(option)}
            label={renderLabel(option)}
            onDelete={() => onDelete(option.uuid)}
          />
        ))}
      </StyledChipsContainer>
    </StyledTreePickerContainer>
  );
}
